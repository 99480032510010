import React, { useReducer, useState } from "react";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import AddressTextField from "../../../UI/AddressTextField";
import HiddenField from "../../../UI/HiddenField";
import InputButton from "../../../UI/InputButton";
import PreviousAddressModal from "./PreviousAddressModal";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
};

const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};

const PostCode = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  slideChange,
  trigger,
  className,
  backClick,
  splitForm,
  getValues,
  setValue
}) => {
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");

  const [prevAddressPopup, setPrevAddressPopup] = useState(false);

  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });

    const txtPostCode = await trigger("txtPostCode");

    if (txtPostCode) {
      await getPostcodeAddress(e.target.value)
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "hide",
              },
            });
          }
        })
        .catch(function () {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };

  const getValue = async (e) => {
    e.preventDefault();
    // document.getElementById("address1").classList.remove("anim_ylw");
    // document.getElementById("NextButton").classList.add("anim_ylw");
    clearErrors([
      "txtHouseNumber",
      "txtAddress2",
      "txtCounty",
      "txtTown",
      "txtCountry",
    ]);

    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtHouseNumber",
        "txtAddress2",
        "txtCounty",
        "txtTown",
        "txtCountry",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }

    if (document.getElementById("address1") != null) {
      document.getElementById("address1").blur();
    }
  };

  const lookupValidation = async (e) => {
    await trigger("txtPostCode");
    let value = document.getElementById("txtPostCode").value;

    if (value === "") {
      document.getElementById("txtPostCode").focus();
    }
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtHouseNumber").value;
    const addressLine2 = document.getElementById("txtAddress2").value;
    const town = document.getElementById("txtTown").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry").value;

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const validateAndProceed = async (e) => {
    let errorFlag = 0;
    const txtPostCode = await trigger("txtPostCode");
    var manual_txtHouseNumber;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtPostCode) {
      manual_txtHouseNumber = await trigger("txtHouseNumber");
      txtAddress = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (manual_txtHouseNumber) {
      manual_txtTown = await trigger("txtTown");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountry");
    } else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtHouseNumber ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLink === 0) {
      const address1 = await trigger("address1");
      if (!address1) {
        errorFlag = 1;
      }
    }

    if (errorFlag !== 0) return false;

    checkPostCodeEntry();
    GtmDataLayer("Postcode", "Completed");
    setPrevAddressPopup(true);
    // slideChange(e);
  };

  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
    </>
  );

  return (
    <div id="slide-3" className={`${className}`}>
      <h5 className="text-black text-center  fw-bold">
        What is your current address?
      </h5>
      <HiddenField
        name="pCode_EntryType"
        inputId="pCode_EntryType"
        inputValue={postCodeEntry}
      />
      <div className="form-group">
        <div className="input-group post">
          <AddressTextField
            type="text"
            placeholder="Your Postcode"
            name="txtPostCode"
            id="txtPostCode"
            className="form-control mb-3 textfield"
            dataId="txtPostCode"
            onBlur={checkValidation}
            autoComplete="off"
            validation={validation({
              required: "Please Enter Postcode",
              pattern: {
                value:
                  /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                message: "Please Enter a Valid Postcode",
              },
              minLength: {
                value: 5,
                message: "Please Enter a Valid Postcode",
              },
            })}
            validationMsg={
              validationMsg.txtPostCode && validationMsg.txtPostCode.message
            }
          />
        </div>
      </div>
      <div
        className="col-lg-12 col-12  p-0 text-right slide8_lkup"
        style={state.lookUp ? { display: "block" } : { display: "none" }}
      >
        <div className="col-xl-12 col-lg-12 d-flex" id="lckup-btsec">
          <input
            className="btn back-btn imx-0"
            type="button"
            value="< Back"
            name="back02"
            onClick={backClick}
          />

          <InputButton
            style={{ float: "none" }}
            name="next"
            className="btn next-btn text-light anim_blue"
            value="Lookup Address"
            btnType="button"
            onClick={lookupValidation}
          />
        </div>
      </div>
      <div
        className={`form-group col-lg-12 col-12 p-0 adj_width`}
        id="currentAddressCollapse"
        style={state.checkSelect ? { display: "block" } : { display: "none" }}
      >
        <label className="control-label white-label addrLabel">
          Select Address
        </label>
        <AddressSelectBox
          className={`form-select textfield mb-2 ${
            !state.getDetails?.country ? "anim_blue" : "bal"
          }`}
          OptionValue={state.getAddress}
          name="address1"
          id="address1"
          onChange={getValue}
          myRef={validation({ required: "Please select address" })}
          validationMsg={
            validationMsg.address1 && validationMsg.address1.message
          }
        />
      </div>
      <div
        className="col-12 text-center"
        style={
          state.getDetails.length === 0
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>
      <div className={`slide8_add row ${state.showManualAddress}`}>
        <div className={`col-lg-12 form-group required-field adj_width`}>
          <AddressTextField
            type="text"
            placeholder="Address Line 1 *"
            name="txtHouseNumber"
            id="txtHouseNumber"
            className="form-control textfield mb-2"
            dataId="txtHouseNumber"
            autoComplete="off"
            readonly={true}
            validation={validation({
              required: "Please Enter Address Line 1",
            })}
            validationMsg={
              validationMsg.txtHouseNumber &&
              validationMsg.txtHouseNumber.message
            }
            value={state.getDetails.line_1}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>

        <div
          className={
            state.getDetails.line_2 == ""
              ? `hide`
              : `col-lg-12 form-group adj_width`
          }
        >
          <AddressTextField
            type="text"
            placeholder="Address Line 2"
            name="txtAddress2"
            id="txtAddress2"
            className="form-control textfield mb-2"
            dataId="txtAddress2"
            autoComplete="off"
            readonly={true}
            validation={validation()}
            validationMsg=""
            value={state.getDetails.line_2}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>

        <div className={`col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="text"
            placeholder="County"
            name="txtCounty"
            id="txtCounty"
            className="form-control textfield mb-2"
            dataId="txtCounty"
            autoComplete="off"
            readonly={true}
            validation={validation()}
            validationMsg=""
            value={state.getDetails.county}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>

        <div className={`col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="text"
            placeholder="Town *"
            name="txtTown"
            id="txtTown"
            className="form-control textfield mb-2"
            dataId="txtTown"
            autoComplete="off"
            readonly={true}
            validation={validation({ required: "Please Enter Town" })}
            validationMsg={
              validationMsg.txtTown && validationMsg.txtTown.message
            }
            value={state.getDetails.town}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className={`col-lg-12 form-group adj_width}`}>
          <AddressTextField
            type="text"
            placeholder="Country *"
            name="txtCountry"
            id="txtCountry"
            className="form-control textfield mb-2"
            dataId="txtCountry"
            autoComplete="off"
            readonly={true}
            validation={validation({ required: "Please Enter Country" })}
            validationMsg={
              validationMsg.txtCountry && validationMsg.txtCountry.message
            }
            value={state.getDetails.country}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
      </div>

      <div
        className="col-lg-12 col-12 text-center p-0"
        style={state.nextClick ? { display: "block" } : { display: "none" }}
      >
        <div className="main_1 w-100 d-flex">
          <input
            className="btn back-btn"
            type="button"
            value="< Back"
            name="back02"
            onClick={backClick}
          />

          <InputButton
            name="address"
            className={`btn next-btn text-light ${
              state.getDetails?.country ? "anim_blue" : "bal"
            }`}
            id="NextButton"
            value="Next"
            btnType="button"
            onClick={validateAndProceed}
            style={{ float: "none" }}
          />
        </div>
      </div>

      {prevAddressPopup && (
        <PreviousAddressModal
          {...{
            validation,
            validationMsg,
            clearErrors,
            setError,
            slideChange,
            trigger,
            className,
            backClick,
            splitForm,
            getValues,
            setValue,
          }}
        />
      )}
    </div>
  );
};

export default PostCode;

import React from "react";
import FFooter from "../../Includes/Layouts/FollowUps/Footer";
const ThankYouPage = () =>{
    return(
        <>
        <div className="maninCssSection">
       <div className="thank">
         <section className="thank-header">
            <div className="container">
               <div className="col-xl-12 col-lg-12 text-center">
                  <img src="../assets/Followups/img/logo.png" alt="" />
               </div>
            </div>
         </section>
         <section className="thank-contnt">
            <div className="container">
               <div className="col-xl-12 col-lg-12 text-center">
                  <img src="../assets/Followups/img/thumb.png" alt="" />
                  <h6 className="fw-bold">Thank you</h6>
                  <p>
                     You have successfully submitted your claim
                  </p>
               </div>
            </div>
         </section>
      </div>
      <FFooter />
      </div>
        </>
    );
}

export default ThankYouPage;
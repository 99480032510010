import React from "react";

const RadioButtonQuest = ({
  id,
  value,
  name,
  className,
  labelName,
  labelClassName,
  onClick,
  onLabelClick,
  validation,
  labelCountString,
  style,
}) => {
  return (
    <>
      <input
        type="radio"
        className={className}
        name={name}
        id={id}
        data-ans-id={value}
        data-quest-id={name}
        data-ans-label={labelName}
        value={value}
        onClick={onClick}
      />
      <label
        htmlFor={id}
        data-ans-id={value}
        data-quest-id={name}
        className={labelClassName}
        onClick={onLabelClick}
        style={style}
      >
        <span className="line-break-span">{labelCountString}</span>
        {labelName}
      </label>
    </>
  );
};

export default RadioButtonQuest;

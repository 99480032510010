import { useHistory } from "react-router-dom";

export const FormData = () => {
  const history = useHistory();
  const setFormData = (response) => {
    if (response.data.response.length) {
      var userDob = response.data.response[0].user_dob.split('/');
      var DobDay = userDob[0];
      var DobMonth = userDob[1];
      var DobYear = userDob[2];
      var spouseDob = response.data.response[0].spouse_dob ? response.data.response[0].spouse_dob.split('/') : '';
      var JointDobDay = spouseDob ? spouseDob[0] : "";
      var JointDobMonth = spouseDob ? spouseDob[1] : "";
      var JointDobYear = spouseDob ? spouseDob[2] : "";
      var formDataParameters = {
        "txtFName": (response.data.response[0].first_name) ? response.data.response[0].first_name : '',
        "txtLName": (response.data.response[0].last_name) ? response.data.response[0].last_name : '',
        "DobDay": DobDay,
        "DobMonth": DobMonth,
        "DobYear": DobYear,
        "JointDobDay": JointDobDay,
        "JointDobMonth": JointDobMonth,
        "JointDobYear": JointDobYear,
        "txtHouseName": (response.data.response[0].housename) ? response.data.response[0].housename : '',
        "txtHouseNumber": (response.data.response[0].address_line1) ? response.data.response[0].address_line1 : '',
        "txtPostCode": (response.data.response[0].postcode) ? response.data.response[0].postcode : '',
        "txtCounty": (response.data.response[0].county) ? response.data.response[0].county : '',
        "txtCountry": (response.data.response[0].country) ? response.data.response[0].country : '',
        "txtAddress2": (response.data.response[0].address_line3) ? response.data.response[0].address_line3 : '',
        "txtTown": (response.data.response[0].town) ? response.data.response[0].town : '',
        "user_sign": (response.data.response[0].signature_image) ? response.data.response[0].signature_image : '',
        "partner_sign": (response.data.response[0].spouse_sign) ? response.data.response[0].spouse_sign : '',
        "jointFName": (response.data.response[0].spouses_first_name) ? response.data.response[0].spouses_first_name : '',
        "jointLName": (response.data.response[0].spouses_last_name) ? response.data.response[0].spouses_last_name : '',
        "txtEmail": (response.data.response[0].email) ? response.data.response[0].email : '',
        "txtPhone": (response.data.response[0].telephone) ? response.data.response[0].telephone : '',
        "question_3": (response.data.response[0].is_joint == '1') ? '5' : '6',
        "page_name": 'QCL_V1',
        "bank": (response.data.response[0].bank_id) ? response.data.response[0].bank_id : '',
      };
      const fData = JSON.stringify(formDataParameters);
      localStorage.setItem('formData', fData);
      localStorage.setItem('bankName', response.data.response[0].bank_name);
      localStorage.setItem('sort_code', response.data.response[0].sort_code);
      localStorage.setItem('account_number', response.data.response[0].account_number);
      let customData = {
        "previous_address_city": (response.data.response[0].previous_address_city) ? response.data.response[0].previous_address_city : '',
        "previous_address_line1": (response.data.response[0].previous_address_line1) ? response.data.response[0].previous_address_line1 : '',
        "previous_address_line2": (response.data.response[0].previous_address_line2) ? response.data.response[0].previous_address_line2 : '',
        "previous_address_country": (response.data.response[0].previous_address_province) ? response.data.response[0].previous_address_province : '',
        "previous_address_province": (response.data.response[0].previous_address_country) ? response.data.response[0].previous_address_country : '',
        "previous_postcode_1": (response.data.response[0].previous_postcode) ? response.data.response[0].previous_postcode : ''
      }
      let customPreData = JSON.stringify(customData);
      localStorage.setItem('customPreviousData', customPreData);
    }
    else {
      history.push("/404");
    }
  }
  return { setFormData }
}

import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';

const Header = () => {
  return (
    <>
      <header className="">
         <div className="container">
            <div className="col-xl-12 col-lg-12 text-center">
               <h1 className="fw-bold text-light">
                  MISSED the PPI Deadline?
               </h1>
               <h2 className="text-light">
                  <strong>Banks face second wave of PPI payouts due to "secret commissions"...</strong>
               </h2>
            </div>
         </div>
		 </header>
		 
    </>
  );
};

export default Header;
import React, { useState,useRef } from "react";
import { Salutation } from "../../../../Constants/Questions";
import { DateOfBirth } from "../../../../Utility/DateOfBirth";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import InputButton from "../../../UI/InputButton";
import SelectBox from "../../../UI/SelectBox";
import TextField from "../../../UI/TextField";
import InputWrapper from "./InputWrapper";

const PersonalDetails = ({
  className,
  slideChange,
  validation,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  slideChangeAddress,
  splitForm,
  backClick,
  setError,
}) => {
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const nameConfirmPopupdiv = useRef([]);
  const previousnamePopupdiv = useRef([]);
  const [fullName, setFullName] = useState("");

  const [prevNamePopup, setPrevNamePopup] = useState(false);

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    var lstSalutationResult = await trigger("lstSalutation");
    const values = getValues();
    var txtFNameResult;
    var txtLNameResult;
    var lstDobDayResult;
    var lstDobMonthResult;
    var lstDobYearResult;

    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }

    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }

    if (txtLNameResult) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }

    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }

    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }

    if (lstDobYearResult) {
    } else {
      document.getElementById("DobYear").focus();
    }

    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult
    ) {
      errorFlag = 1;
    }

    if (errorFlag === 0) {
      let fName =
        values["txtFName"] +
        (" " + values["txtMName"] + " ").replace("  ", " ") +
        values["txtLName"];
      // capitalize first letters
      fName = fName.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      });
      setFullName(fName);
      //window.scrollTo(0, 500);
      /*nameConfirmPopupdiv.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });*/
      showConfirmationPopup(e);
      //slideChange(e);
    } else {
      return false;
    }
  };

  const showConfirmationPopup = async (e) => {
    setConfirmationPopup(true);
    nameConfirmPopupdiv.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
  };

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };

  return (
    <div id="slide-2" className={` ${className}`}>
      <h5 className="text-black text-center  fw-bold">
        Please enter your name and date of birth:
      </h5>
      <p className="note-red">
        (Make sure the name you enter should match with your lender records)
      </p>

      <SelectBox
        className="form-select mb-3 textfield"
        OptionValue={Salutation}
        name="lstSalutation"
        id="lstSalutation"
        disableFirstOption={true}
        onChange={() => clearErrors("lstSalutation")}
        clearErrors={clearErrors}
        myRef={validation({ required: "Please Select Title" })}
        validationMsg={
          validationMsg.lstSalutation && validationMsg.lstSalutation.message
        }
      />

      <InputWrapper isMandatory={true}>
        <TextField
          type="text"
          className="form-control mb-3 textfield"
          placeholder="First Name*"
          name="txtFName"
          id="txtFName"
          onBlur={namevalidate}
          validation={validation({
            required: "Please Enter First Name",
            minLength: {
              value: 3,
              message: "Please Enter Valid First Name",
            },
            pattern: {
              value: /^[a-zA-Z\s]+$/,
              message: "Your Name is Invalid. Please Recheck",
            },
          })}
        />
      </InputWrapper>

      {validationMsg.txtFName && (
        <span className="error_msg " id="email_err">
          {validationMsg.txtFName.message}
        </span>
      )}

      <TextField
        type="text"
        className="form-control mb-3 textfield"
        placeholder="Middle Name"
        name="txtMName"
        id="txtMName"
        onBlur={namevalidate}
        validation={validation({
          required: "Please Enter Middle Name",
          minLength: {
            value: 3,
            message: "Please Enter Valid Middle Name",
          },
          pattern: {
            value: /^[a-zA-Z\s]+$/,
            message: "Your Name is Invalid. Please Recheck",
          },
        })}
      />

      <InputWrapper isMandatory={true}>
        <TextField
          type="text"
          className="form-control mb-3 textfield"
          placeholder="Surname*"
          name="txtLName"
          id="txtLName"
          onBlur={namevalidate}
          validation={validation({
            required: "Please Enter Surname",
            minLength: {
              value: 3,
              message: "Please Enter Valid Surname",
            },
            pattern: {
              value: /^[a-zA-Z\s]+$/,
              message: "Your Name is Invalid. Please Recheck",
            },
          })}
        />
      </InputWrapper>

      {validationMsg.txtLName && (
        <span className="error_msg " id="email_err">
          {validationMsg.txtLName.message}
        </span>
      )}

      <div className="dateofbirth">
        <DateOfBirth
          textDob="Your Date Of Birth"
          validation={validation}
          validationMsg={validationMsg}
        />
      </div>
      <div className="col-xl-12 col-lg-12 d-flex">
        <input
          className="btn back-btn"
          type="button"
          name="back01"
          id="back-2"
          value="< Back"
          onClick={backClick}
        />
        <InputButton
          name="PersonalDetails"
          className="btn next-btn text-light"
          id="click-2"
          value="Next"
          btnType="button"
          onClick={basicDetailsValidation}
        />
      </div>

      <div ref={nameConfirmPopupdiv}
        className="show_box"
        id="shw-1"
        style={{ display: !confirmationPopup ? "none" : "block" }}
      >
        <div className="col-lg-12 text-center show_txt">
          <p>
            <span style={{ fontWeight: 400, fontSize: "1.1rem" }}>
              Please confirm this is how your name appears at the lenders:
            </span>
            <br />
            {fullName}
          </p>
          <div style={{ width: "100%", textAlign: "center" }}>
            <input
              className="btn bckbt bkbt-first"
              id="noo-1"
              value="No, I'll Update"
              type="button"
              onClick={() => {
               
                setConfirmationPopup(false);
              }}
            />
            <input
              className="btn bckbt"
              id="yss-1"
              value="Yes, Correct >"
              name="PersonalDetails"
              type="button"
              onClick={(e) => {
                setConfirmationPopup(false);
                GtmDataLayer("Name, DOB", "Completed");
                let salutaionValue = document.getElementById("lstSalutation").value;
                if(salutaionValue == "Mr" || salutaionValue == "Sir" || salutaionValue == "Lord"){
                  setPrevNamePopup(false);
                  slideChange(e);
                }
                else{
                  setPrevNamePopup(true);
                  previousnamePopupdiv.current.scrollIntoView({
                    behavior: "smooth",
                    top: 0
                  });
                }
              }}
            />
          </div>
        </div>
      </div>

      <div ref={previousnamePopupdiv}
        className="show_box"
        id="shw-1"
        style={{ display: !prevNamePopup ? "none" : "block" }}
      >
        <div className="col-lg-12 show_txt py-3 text-center">
          <p className="mb-1">Previous Name?</p>
          <div className="mb-1">
            <label className="mb-3 text-white-50">
              (Leave blank if not applicable)
            </label>

            <input
              type="text"
              className="form-control"
              placeholder="Previous Name"
              name="previous_name"
              id="previous_name"
              ref={validation({
                // required: "Please Enter Previous Name",
                // minLength: {
                //   value: 3,
                //   message: "Please Enter Valid Previous Name",
                // },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Previous Name is Invalid. Please Recheck",
                },
              })}
              onChange={(e) => {
                trigger("previous_name");
              }}
            />

            {validationMsg.previous_name && (
              <span className="error_msg " id="email_err">
                {validationMsg.previous_name.message}
              </span>
            )}
          </div>

          <InputButton
            name="PersonalDetails"
            className="btn next-btn next-btn-sm text-light"
            id="click-2"
            value="Next"
            btnType="button"
            onClick={(e) => {
              trigger("previous_name").then(() => {
                if (validationMsg.previous_name) return;

                setPrevNamePopup(false);
                GtmDataLayer("Previous Name", "Completed");
                slideChange(e);
              });
            }}
          />

          <button
            name="PersonalDetails"
            type="button"
            className="btn btn-txt w-100 text-white p-0 skipBtn"
            onClick={(e) => {
              setPrevNamePopup(false);
              slideChange(e);
            }}
          >
            Skip
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;

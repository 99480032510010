import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import LoaderModal from "./LoaderModal";

function ConfirmModal({
  show = true,
  currentSignature,
  onConfirm = () => {},
  onClose = () => {},
  retake = () => {},
  children,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  });

  if (isLoading) return <LoaderModal />;

  return (
    <Modal
      show={show}
      size="md"
      align="center"
      className="modal modal-sp-preview confirm-modal-popup footer-modals fade"
      dialogClassName="modal-dialog modal-md modal-dialog-centered"
      centered
      {...props}
    >
      <Modal.Body className="modal-body-sp2 show modal-body">
        <div className="modal-content">
          {!isLoading && (
            <>
              <div className="modal-body-sp2 show modal-body">
                <h2>Does this look like the signature on your ID?</h2>
                <h3>
                  <i>
                    We must provide lenders with the matching details in their
                    system to ensure a successful outcome. It should appear as
                    if it were handwritten.
                  </i>
                </h3>

                <div id="confirmSignature">
                  {children || (
                    <img
                      src={currentSignature}
                      alt="SignaturePreview"
                      className="sgp"
                    />
                  )}
                </div>
              </div>

              <div
                className="modal-footer-sp2 show modal-footer "
                style={{ border: "0px" }}
              >
                <button
                  type="button"
                  className="btn-clear red-btn btn btn-primary"
                  onClick={retake}
                >
                  Retake
                </button>
                <button
                  type="button"
                  className="btn-submit green-btn next09 btn btn-primary"
                  onClick={onConfirm}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>{" "}
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmModal;

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Footer from "../Includes/Layouts/Plevin/Footer";

const Thankyou = () => {
   localStorage.clear();
   const [showPrivacy, setShowPrivacy] = useState(false);
   const [showCookie, setShowCookie] = useState(false);
   const [showComplaint, setShowComplaint] = useState(false);
   const modalClick = (clickValue) => {
      switch (clickValue) {

         case "showPrivacy": {
            setShowPrivacy(true);
            break;
         }
         case "exitPrivacy": {
            setShowPrivacy(false);
            break;
         }
         case "showCookie": {
            setShowCookie(true);
            break;
         }
         case "exitCookie": {
            setShowCookie(false);
            break;
         }
         case "showComplaint": {
            setShowComplaint(true);
            break;
         }
         case "exitComplaint": {
            setShowComplaint(false);
            break;
         }
      }
   };
   return (
      <>
         <div className="maninCssSection">
            <div className="thank">

               <section className="thank-header">
                  <div className="container">
                     <div className="col-xl-12 col-lg-12 text-center">
                        <img src="/assets/Thankyou/img/logo.png" alt="" />
                     </div>
                  </div>
               </section>
               <section className="thank-contnt">
                  <div className="container">
                     <div className="col-xl-12 col-lg-12 text-center">
                        <img src="/assets/Thankyou/img/thumb.png" alt="" />
                        <h6 className="fw-bold">Thank you</h6>
                        <p>
                           You have successfully submitted your claim
                        </p>
                     </div>
                  </div>
               </section>
            </div>
            <Footer/>
         </div>
      </>
   )
}

export default Thankyou;

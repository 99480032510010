const GtmDataLayer = ( question, answer ) => {
  console.log("Question-" + question + ", Answer-" + answer);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "slideclick",
    question: question,
    answer: answer,
  });
};

export default GtmDataLayer;
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/css/Questionnaire_4_5.scss";
import {
  GtmQuestions,
  questinnare1,
  questinnare10,
  questinnare2,
  questinnare3,
  questinnare4,
  questinnare5,
  questinnare6,
  questinnare7,
  questinnare8,
  questinnare9,
  questinnare16,
  questinnare17,
  questinnare18,
  questinnare19,
  questinnare20,
  questinnare_1_4,
  questinnare_5_1,
  questinnare_5_2,
} from "../../Constants/Questions";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { userInfo } from "../../Hooks/userInfo";
import { CheckUUID } from "../../Utility/CheckUUID";
import { FormData } from "../../Utility/FormData";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import QuestionnaireFooter from "../Includes/Layouts/Questionnaire/QuestionnaireFooter";
import QuestionnaireWrapper from "../Includes/Layouts/Questionnaire/QuestionnaireWrapper";
import VehicleRegNumberInput from "../Includes/Layouts/Questionnaire/VehicleRegNumberInput";

const Questionnaire = () => {
  const { isCheck } = CheckUUID();
  isCheck();
  const scrollRef = useRef([]);
  const history = useHistory();
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [checkboxValues, SetCheckboxValues] = useState([]);
  const [displayError, SetDisplayError] = useState("d-none");
  const [modalSub1Ans, setModalSub1Ans] = useState(null);
  const [modalSub2Ans, setModalSub2Ans] = useState(null);
  const query_string = localStorage.getItem("querystring");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const [checkQuestion, setCheckQuestion] = useState(0);
  const [percentage, SetPercentage] = useState(0);
  const [lastClickQuest, setLastClickQuest] = useState(1);

  const [splitName, setSplitName] = useState();
  const { getUserInfo } = userInfo();
  const { setFormData } = FormData();
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();

  useEffect(() => {
    if (formdata === undefined || formdata === null || formdata == "") {
      (async () => {
        const response = await getUserInfo(uuid);
        const set_formdata = setFormData(response);
        let dataLength = response.data.response.length;
        if (dataLength > 0) {
          const pageName = response.data.response[0].page_name
            ? response.data.response[0].page_name
            : " ";
          setSplitName(pageName);
        }
      })();
    } else {
      const pageName = formdata.page_name;
      setSplitName(pageName);
    }
  }, [splitName]);
  const onselectAnswer = async (e) => {
    console.log("onSelectAnswer");
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let ansLabel = e.target.getAttribute("data-ans-label");
    let inputAnswer = e.target.getAttribute("data-input-answer");

    let gtmQuesArr = GtmQuestions;

    if (gtmQuesArr[String(selectedQuest)] !== undefined) {
      GtmDataLayer(gtmQuesArr[String(selectedQuest)], "");
    }

    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    if ((selectedAns || inputAnswer) && selectedQuest) {
      let question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: ansLabel,
        input_answer: inputAnswer,
      };
      storeQuestionnire(question_data);
      var existingEntries =
        JSON.parse(localStorage.getItem("questionData")) ?? [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const getStoredValue = (question_id) => {
    var existingEntries =
      JSON.parse(localStorage.getItem("questionData")) ?? [];
    let storedValue = existingEntries.filter(
      (item) => item.question_id == question_id
    );

    return storedValue.pop();
  };

  const nextOnClick = () => {
    setCheckQuestion(0);
    if (checkboxValues.length > 0) {
      scrollRef.current[12]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      if (!completedQuestArr.includes(11)) {
        setCompletedQuestArr([...completedQuestArr, 11]);
      }
      let ansLabel = "";
      const checkBoxArr = checkboxValues.map(async (answer, index) => {
        let question_data = "";
        let AnsTextMap = {
          26: "Option-1",
          27: "Option-2",
          28: "Option-3",
          29: "Option-4",
          30: "Option-5",
          31: "Option-6",
          32: "Option-7",
          33: "Option-8",
          34: "Option-9",
          35: "Option-10",
          36: "Option-11",
          37: "Option-12",
          38: "Option-13",
          39: "Option-14",
        };
        if (answer === "27") {
          ansLabel = AnsTextMap[answer];
          question_data = {
            question_id: "11",
            option_id: answer,
            answer_text: ansLabel,
            input_answer: modalSub1Ans,
          };
          ansLabel = "";
        } else if (answer === "32") {
          ansLabel = AnsTextMap[answer];
          question_data = {
            question_id: "11",
            option_id: answer,
            answer_text: ansLabel,
            input_answer: modalSub2Ans,
          };
          ansLabel = "";
        } else {
          ansLabel = AnsTextMap[answer];
          question_data = {
            question_id: "11",
            option_id: answer,
            answer_text: ansLabel,
            input_answer: "",
          };
        }
        var existingEntries = JSON.parse(localStorage.getItem("questionData"));
        if (existingEntries == null) existingEntries = [];
        localStorage.setItem(
          "currentquestionData",
          JSON.stringify(question_data)
        );
        existingEntries.push(question_data);
        localStorage.setItem("questionData", JSON.stringify(existingEntries));
        storeQuestionnire(question_data);
      });
    } else {
      SetDisplayError("d-block");
    }
  };

  useEffect(() => {
    const totalQuestArr = [
      5, 6, 7, 8, 21, 9, 10, 11, 12, 13, 22, 23, 14, 16, 17, 18, 19, 20,
    ];
    var questIntersection = totalQuestArr.filter(
      (x) => !completedQuestArr.includes(x)
    );
    SetPercentage(
      Math.round((completedQuestArr.length * 100) / totalQuestArr.length)
    );
    scrollRef.current[questIntersection[0]]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    setLastClickQuest(questIntersection[0]);
    let completeQuestCount = questIntersection.length;
    if (completeQuestCount == 0) {
      pushPageChange();
    }
  }, [completedQuestArr]);

  const handleCheckBoxChange = (e) => {
    SetDisplayError("d-none");
    let checkVal = e.target.value;
    if (e.target.checked == true) {
      SetCheckboxValues([...checkboxValues, checkVal]);
    } else {
      SetCheckboxValues(checkboxValues.filter((e) => e !== checkVal));
    }
  };
  const onModalClick = (e) => {
    let selectedAns = e.target.getAttribute("data-ans-id");
    let selectedQuest = e.target.getAttribute("data-quest-id");
    let selectedAnsTxt = e.target.getAttribute("data-ans-label");
    if (selectedQuest === "sub1") {
      setModalSub1Ans(selectedAns);
    } else if (selectedQuest === "sub2") {
      setModalSub2Ans(selectedAns);
    }
  };
  const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      query_string,
      formdata
    );
  };
  const pushPageChange = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      history.push(
        `/analysing?uuid=${visitorParameters.visitor_parameters.uuid}`
      );
    }, 300);
  };
  useEffect(() => {
    scrollRef.current[lastClickQuest]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }, [lastClickQuest]);
  const handleDownClick = () => {
    if (lastClickQuest <= 9) {
      setLastClickQuest(lastClickQuest + 1);
    }
  };
  const handleUpClick = () => {
    if (lastClickQuest >= 2) {
      setLastClickQuest(lastClickQuest - 1);
    }
  };
  return (
    <>
      <GetVisitorsParams />
      <div className="Questionnaire">
        <section className="questionspart">
          <div className="container">
            <div className="row">
              <div className="offset-lg-3 col-xl-6 offset-md-1 col-md-10 col-12 col-sm-12">
                <form>
                  <div
                    className="radio-box animated fadeInUp"
                    id="question_id_60"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare1}
                      reff={(el) => (scrollRef.current[5] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div className="radio-box  animated fadeInUp">
                    <QuestionnaireWrapper
                      questArr={questinnare2}
                      reff={(el) => (scrollRef.current[6] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box animated fadeInUp"
                    id="question_id_62"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare3}
                      reff={(el) => (scrollRef.current[7] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box  animated fadeInUp"
                    id="question_id_63"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare4}
                      reff={(el) => (scrollRef.current[8] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box animated fadeInUp"
                    id="question_id_70"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare_1_4}
                      reff={(el) => (scrollRef.current[21] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box  animated fadeInUp"
                    id="question_id_64"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare5}
                      reff={(el) => (scrollRef.current[9] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box  animated fadeInUp"
                    id="question_id_65"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare6}
                      reff={(el) => (scrollRef.current[10] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box  animated fadeInUp"
                    id="question_id_66"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare7}
                      reff={(el) => (scrollRef.current[11] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box  animated fadeInUp"
                    id="question_id_67"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare8}
                      reff={(el) => (scrollRef.current[12] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box  animated fadeInUp"
                    id="question_id_68"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare9}
                      reff={(el) => (scrollRef.current[13] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box  animated fadeInUp"
                    id="question_id_71"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare_5_1}
                      reff={(el) => (scrollRef.current[22] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box  animated fadeInUp"
                    id="question_id_72"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare_5_2}
                      reff={(el) => (scrollRef.current[23] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box  animated fadeInUp"
                    id="question_id_69"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare10}
                      reff={(el) => (scrollRef.current[14] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box animated fadeInUp"
                    id="question_id_70"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare16}
                      reff={(el) => (scrollRef.current[16] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box animated fadeInUp"
                    id="question_id_71"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare17}
                      reff={(el) => (scrollRef.current[17] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box animated fadeInUp"
                    id="question_id_72"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare18}
                      reff={(el) => (scrollRef.current[18] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box animated fadeInUp"
                    id="question_id_73"
                  >
                    <QuestionnaireWrapper
                      questArr={questinnare19}
                      reff={(el) => (scrollRef.current[19] = el)}
                      labelclicked={onselectAnswer}
                    />
                  </div>
                  <div
                    className="radio-box animated fadeInUp"
                    id="question_id_74"
                  >
                    <VehicleRegNumberInput
                      questArr={questinnare20}
                      reff={(el) => (scrollRef.current[20] = el)}
                      vehicleCount={
                        getStoredValue(18)?.answer_text?.includes("more")
                          ? 4
                          : parseInt(getStoredValue(18)?.answer_text ?? "1")
                      }
                      labelclicked={onselectAnswer}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <div className="modal fade" id="formoptionmodal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title" id="formoptionLabel">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="modalform form-part">
                  <form>
                    <div className="mb-3">
                      <input
                        type="radio"
                        className="radio"
                        name="12"
                        id="ans_29"
                      />
                      <label htmlFor="ans_29" className="radioa ">
                        <span>A</span> Option-1
                      </label>
                      <input
                        type="radio"
                        ckecked="ckecked"
                        className="radio"
                        name="12"
                        id="ans_30"
                      />
                      <label htmlFor="ans_30" className="radioa ">
                        <span>B</span> Option-2
                      </label>
                      <input
                        type="radio"
                        className="radio"
                        name="12"
                        id="ans_31"
                      />
                      <label htmlFor="ans_31" className="radioa ">
                        <span>C</span> Option-3
                      </label>
                      <input
                        type="radio"
                        ckecked="ckecked"
                        className="radio"
                        name="12"
                        id="ans_32"
                      />
                      <label htmlFor="ans_32" className="radioa ">
                        <span>D</span> Option-4
                      </label>
                      <input
                        type="radio"
                        className="radio"
                        name="12"
                        id="ans_33"
                      />
                      <label htmlFor="ans_33" className="radioa ">
                        <span>E</span> Option-5
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="formoptionmodal2">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title" id="formoptionLabel2">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="modalform form-part">
                  <form>
                    <div className="mb-3">
                      <input
                        type="radio"
                        className="radio"
                        name="12"
                        id="ans_34"
                      />
                      <label htmlFor="ans_34" className="radioa ">
                        <span>A</span> Yes
                      </label>
                      <input
                        type="radio"
                        ckecked="ckecked"
                        className="radio"
                        name="12"
                        id="ans_35"
                      />
                      <label htmlFor="ans_35" className="radioa ">
                        <span>B</span> No
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <QuestionnaireFooter
          percentage={percentage}
          onDownClick={handleDownClick}
          onUpClick={handleUpClick}
        />
      </div>
    </>
  );
};

export default Questionnaire;

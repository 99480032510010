import React, { useState, useEffect, useContext }  from "react";
import "../../../assets/followUps/dist/css/main.css";
import FLoader from "../../Includes/Layouts/FollowUps/Loader";
import FSignature from "../../Includes/Layouts/FollowUps/Signature";
import SignatureOnlySlide from "../../Includes/Layouts/FollowUps/SignatureOnlySlide";
import {queryString, urlParams } from "../../../Utility/QueryString";
import { Api } from "../../../api/Api";
import { useFollowupLoad } from "../../../Hooks/useFollowupLoad";
import {useGetToken}  from "../../../Hooks/useGetToken";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
const FollowUpSignature = () =>{

   const query = new URLSearchParams(window.location.search);
   const userToken = urlParams.get("atp_sub2");
   const atpSub6 = (urlParams.get("atp_sub6")) ? urlParams.get("atp_sub6") : 'FLP';
   localStorage.setItem('user_token', userToken);
   localStorage.setItem('atp_sub6', atpSub6);

   //const userToken = query.get("token"); 
   //console.log('userToken first load');
   //console.log(userToken);
   const[showLoader, setShowLoader] = useState(true);
   const [signatureClass, setSignatureClass] = useState(false);
   const [showLoad, setshowLoad] = useState("show");
   const [allFollowupData, setFollowupData] = useState({});
   const [pendingStatus, setPendingStatus] = useState([]);
   const [agreeTerms,setAgreeTerms] = useState(0);
   const [showUserName, setUserName] = useState("");
   const [showSpouseName, setSpouseName] = useState("");
   const [pendingQuestionnaire, setPendingQuestionnaire] = useState([]);
   const [isJoint, setIsJoint] = useState('');
   const [currentUuid, setCurrentUuid] = useState('');
   const { loadFollowup, isuseFollowupLoad } = useFollowupLoad();
   const { loadUserToken, isUserTokenLoaded } = useGetToken();
   const [userInfo, setUserInfo] = useState({});
   const { visitorParameters } = useContext(VisitorParamsContext);
   const history = useHistory();
    const setLoader = (e) => {
      setshowLoad("show");
    };
    const hideLoader = (e) => {
      setshowLoad("hide");
    };
   useEffect(() => {
      setTimeout(() => setShowLoader(false), 3000);
  }, []);

  useEffect(() => {
   localStorage.setItem('user_token', userToken);
   (async () => {
      const response = await Api.get("/api/v1/followup/get-pending-details?query_string=" + queryString, {}
      );
      if(response.data.valid == 0) {
        history.push('/404');
        return;
      }
     
     setAgreeTerms(response.data.response.pending_details.agree_terms);
     const followupDetails = response.data.response.followup_data;
     const pendingQuestions = response.data.response.pending_details.pending_question;
     const filledQuestions = response.data.response.pending_details.filled_question;
     setUserName(response.data.response.user_info.user_name
     );
     setUserInfo(response.data.response.user_info);
     setSpouseName(response.data.response.user_info.spouse_name
     );
     setFollowupData({
       flpData: response.data.response
     });
     
     setPendingQuestionnaire({ pendingQuestions });
     setIsJoint(response.data.response.pending_details.is_joint);
     localStorage.setItem('user_info', JSON.stringify(response.data.response.user_info));
     localStorage.setItem('account_type', response.data.response.pending_details.is_joint);
     setCurrentUuid(response.data.response.user_info.uuid);
  
     if (response.data.status === "Success") {
       const flpResponse = await loadFollowup(
         "followup_load",
         followupDetails,
         "Split_1",
         queryString,
         "v2"
       )
       setTimeout(() => { hideLoader() }, 2000);  
        
       console.log(response.data.response);
       
        if (response.data.response.pending_details.valid_signature_exist == 1) {
          history.push("followup/thankyou?"  + queryString);
        }
        else if (response.data.response.pending_details.valid_signature_exist === 0) {
          history.push("/followup_signature?" + queryString);
        }
        else{
          history.push('followup/thankyou?uuid'  + queryString);
        }
     }
   })();
 }, []);
    return(
        <div className="followUpSection">

        <div className="follow-up">
         <section className="followup-inner">
            <div className="container">
               <div className="row">
                  <div className="col-12 col-sm-12 l_bg">
                    
                     { showLoader? <FLoader showLoad = { showLoad }/> : ''}
                    
                  </div>
               </div>
            </div>
         </section>
      </div>
    
     <SignatureOnlySlide
      allFollowupData={allFollowupData}
      userToken = {userToken}
      queryString = {queryString}
      userInfo={userInfo}
     />
 
        </div>
    );

}

export default FollowUpSignature;
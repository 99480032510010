import React from "react";

function InputWrapper({ children, isMandatory = true }) {
  return (
    <div className="inputwrapper">
      {children}
      {!!isMandatory && (
        <span className="blink">
          <strong>Mandatory</strong>
        </span>
      )}
    </div>
  );
}

export default InputWrapper;

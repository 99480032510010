import React, { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import QueryStringContext from "../../Contexts/QueryString";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataLayer } from "../../Hooks/useDataLayer";
import { useQuestionnaire } from "../../Hooks/usePcpQuestionnaire";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import "../../assets/Analysing/css/main.scss";

const Analysing = (props) => {
  const history = useHistory();

  const { visitorParameters } = useContext(VisitorParamsContext);

  const uuid = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryUuid = urlParams.get("uuid");
    return queryUuid ? queryUuid : visitorParameters.visitor_parameters.uuid;
  }, [visitorParameters.visitor_parameters.uuid]);

  const { queryString } = useContext(QueryStringContext);
  const { isLoading, saveQuestionnaire } = useQuestionnaire();

  const splitName = useMemo(() => {
    const userInfo = localStorage.getItem("user_info");
    const split_name = localStorage.getItem("split_name");
    if (!userInfo) return split_name;

    const local_split_name = JSON.parse(userInfo)?.split_name;

    return local_split_name ? local_split_name : split_name;
  }, []);

  console.log({ splitName });

  const { GTMDataLayer } = useDataLayer();

  const handleClick = useCallback(
    async (e) => {
      if (isLoading) return;

      const value = e.target.id;

      const visitorData = { ...visitorParameters.data };
      visitorData.source = visitorData?.source ? visitorData.source : "live";

      const tag = "PCP Claim?";
      if (splitName == "Plevin/CL_PLV1.1" && tag) {
        GTMDataLayer({ question: tag });
      }

      const response = await saveQuestionnaire(
        visitorParameters.visitor_parameters,
        {
          pcp_answer_text: value,
        },
        "pcp_analysis_form",
        visitorData,
        queryString,
        {},
        { page_name: splitName }
      );

      if (value === "yes") {
        history.push(`/pcp-questionnaire?uuid=${uuid}`);
      } else {
        // history.push(`/preview?uuid=${uuid}`);
        history.push(`/thankyou?uuid=${uuid}`);
      }
    },
    [
      GTMDataLayer,
      history,
      isLoading,
      queryString,
      saveQuestionnaire,
      splitName,
      uuid,
      visitorParameters.data,
      visitorParameters.visitor_parameters,
    ]
  );

  return (
    <div id="analysingPage">
      <GetVisitorsParams />

      <section className="bg_sec1">
        <div className="container bg_img1">
          <img src="/assets/Thankyou/img/logo.png" alt="Logo" />
          <div className="row">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>
                A large portion of our customers we serve who qualify for a
                Plevin claim also qualify for a PCP claim. Would you like to
                move forward with a check to see if you qualify for a PCP claim?
              </h2>
              <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12 mt-5">
                <label className="btn_option1 text-white" htmlFor="yes">
                  <input
                    tabIndex={1}
                    type="radio"
                    className="sctop1"
                    id="yes"
                    data-direction="next"
                    defaultValue={11}
                    autoComplete="off"
                    onChange={handleClick}
                  />
                  <span>Yes, Proceed to PCP Check</span>
                </label>

                <label className="btn_option2 text-white" htmlFor="no">
                  <input
                    tabIndex={1}
                    type="radio"
                    className="sctop1"
                    id="no"
                    data-direction="next"
                    defaultValue={11}
                    autoComplete="off"
                    onChange={handleClick}
                  />
                  <span>No</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Analysing;

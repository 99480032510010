import { Api } from "../api/Api";

export const useFollowupUserSignature = () => {
  const saveFollowupUserSignature = async (
    signature_data,
    followup_data,
    message_type
  ) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    var signaturefollowData = {};
    signaturefollowData[token] = {
      signature_data: signature_data,
      followup_data: followup_data,
      message_type: message_type
    };
    
    localStorage.setItem('signaturefollowData', JSON.stringify(signaturefollowData));
    const signatureSubmit = await Api.post("/api/v1/data-ingestion-pipeline", {
      
      signature_data,
      followup_data,
      message_type,
    });
   
    return signatureSubmit;
  };
  return {
    saveFollowupUserSignature,
  };
};

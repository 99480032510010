import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import "../../assets/Signature_2/css/main.scss";
import logo from "../../assets/Signature_2/img/logo.png";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useSignature } from "../../Hooks/useSignature";
import { CheckUUID } from "../../Utility/CheckUUID";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { queryString } from "../../Utility/QueryString";
import ConfirmModal from "../Includes/Layouts/Signature_2/ConfirmModal";
import PrivacyModal from "../Includes/Layouts/Signature_2/PrivacyModal";

const initialState = {
  touched: false,
  isChecked: false,
  isAgreed: true, // Assumed agreed by default
  isCanvasEmpty: true,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
        touched: true,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const Errors = ({ state }) => {
  if (!state || !state.touched) return <></>;

  return (
    <>
      {state.isCanvasEmpty && (
        <div
          className="sign_msg col-12 text-center sign_msg"
          id="signatures_required"
        >
          Signature is required!
        </div>
      )}
      {!state.isValid && (
        <div
          className="sign_msg col-12 text-center sign_msg"
          id="signatures_valid"
        >
          Draw valid signature!
        </div>
      )}
    </>
  );
};

const Signature = () => {
  const { isCheck } = CheckUUID();
  isCheck();

  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const confirmSignPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);
  const confirmSignatureWrapRef = useRef(null);
  const [showModal, setShowModal] = useState("");
  const [currentSignature, setCurrentSignature] = useState("");

  const signPadRefs = useMemo(() => {
    return [confirmSignPadRef, signPadRef];
  }, []);

  // sync signature
  useEffect(() => {
    signPadRefs.forEach((ref) => {
      ref.current?.fromDataURL(currentSignature, {
        width: ref.current.getCanvas().width,
        height: ref.current.getCanvas().height,
      });
    });
  }, [
    currentSignature,
    signPadRefs,
    signatureWrapHeight,
    signatureWrapWidth,
    showModal,
  ]);

  const validateSignature = useCallback((ref) => {
    const signPad = ref.current;
    dispatch({
      type: "signPadChange",
      payload: { value: signPad.isEmpty() },
    });

    const rpData = signPad.toData();
    var signLength = 0;
    for (var i = 0; i < rpData.length; i++) {
      signLength += Object.keys(rpData[i]).length;
    }

    if (signLength <= 13) {
      return dispatch({
        type: "validSignature",
        payload: { value: false },
      });
    }

    dispatch({
      type: "validSignature",
      payload: { value: true },
    });
  }, []);

  const handleChange = useCallback(
    (ref) => {
      validateSignature(ref);

      const newSign = ref?.current?.getCanvas().toDataURL("image/png");
      if (newSign === currentSignature) return;
      setCurrentSignature(newSign);

      if (!currentSignature) return;
    },
    [currentSignature, validateSignature]
  );

  const confirmSubmission = useCallback(() => {
    try {
      validateSignature(signPadRef);
      if (!state.isValid) return;

      setShowModal("confirm");
    } catch (e) {
      console.warn(e);
    }
  }, [state.isValid, validateSignature]);

  const submitSignature = useCallback(() => {
    if (!state.isValid) return;

    (async () => {
      try {
        const response = await saveSignature(
          currentSignature,
          visitorParameters.visitor_parameters,
          formParameters,
          visitorParameters.data,
          queryString,
          message_type
        );

        GtmDataLayer("Signature", "Completed");

        if (response.data.status === "Success") {
          const formData = JSON.parse(localStorage.getItem("formData"));
          if (formData === undefined || formData === null || formData === "") {
            localStorage.setItem("formData", JSON.stringify(formData));
          } else {
            formData.user_sign = currentSignature;
            localStorage.setItem("formData", JSON.stringify(formData));
          }

          const { uuid } = visitorParameters.visitor_parameters;
          const split_name = localStorage.getItem("split_name") ?? "";
          history.push(`/proceed?uuid=${uuid}&split_name=${split_name}`);
        }
      } catch (e) {
        console.warn(e);
      }

      setShowModal("");
    })();
  }, [
    currentSignature,
    formParameters,
    history,
    saveSignature,
    state.isValid,
    visitorParameters.data,
    visitorParameters.visitor_parameters,
  ]);

  useEffect(() => {
    function handleResize() {
      const wrapper = signatureWrapRef.current;
      const { clientWidth, clientHeight } = wrapper;
      const height = Math.max(clientWidth / 2, 200);

      if (signatureWrapWidth) {
        const deltaWidth = signatureWrapWidth - clientWidth;
        const isSignificant = deltaWidth > 10;
        // console.log("isSignificant", isSignificant, deltaWidth);
        if (!isSignificant) return;
      }

      // the difference is significant, so update the state
      setSignatureWrapWidth(clientWidth);
      setSignatureWrapHeight(height);

      // fix wrapper size (higher specificity will override inline styles)
      wrapper.style.height = `${height}px`;
      wrapper.style.minHeight = `${height}px`;
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [signatureWrapWidth]);

  const clearSign = () => {
    signPadRefs.forEach((ref) => {
      ref.current?.clear();
    });

    setCurrentSignature("");
  };

  return (
    <>
      <GetVisitorsParams />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12 p-0 m-auto">
              <div className="signature-part">
                <div className="col-12 text-center mb-3">
                  <img src={logo} className="sign-logo" alt="" />
                </div>
                <div className="modal-content sign-popstyle">
                  <div className="modal-header">
                    <h2 className="h2_style w-100">
                      Finally, we need your electronic signature below to
                      proceed. Please note that your electronic signature will
                      be included on all forms we submit to your lender
                    </h2>
                  </div>

                  <div className="ensure_li">
                    <div className="offset-lg-3 col-lg-6 col-12 sign-question nopadmob">
                      <ul className="signature-ul">
                        <li> Sign using your finger, mouse or stylus</li>
                        <li> Keep it fully contained within the box</li>
                        <li> Ensure it is a true likeness of your signature</li>
                      </ul>
                    </div>

                    <div className="signature_canvaseWrap">
                      <div
                        className="signature-pad anim_bt d-lg-block web-canvas"
                        ref={signatureWrapRef}
                      >
                        <SignatureCanvas
                          canvasProps={{
                            id: "myID",
                            width: signatureWrapWidth,
                            height: signatureWrapHeight,
                          }}
                          ref={signPadRef}
                          onEnd={() => handleChange(signPadRef)}
                        />
                      </div>

                      <Errors state={state} />
                    </div>
                  </div>

                  <div className="text-left bord pb-0" id="acceptterms_outer">
                    <div className="custom-control custom-checkbox pb-0">
                      <p className="mb-0">
                        By signing these terms, I authorise ClaimLion Law to run
                        a soft credit check on me to identify information to
                        support my claim(s). I understand this check will not
                        affect my credit score.
                      </p>

                      <p className="mb-0">
                        I consent for my signature to be applied to a{" "}
                        <a href="assets/LOA/LOA.pdf" target="_blank">
                          Letter of Authority
                        </a>{" "}
                        to be sent with my{" "}
                        {/* <a href="assets/LOC/LOC.pdf" target="_blank"> */}
                        Letter of Complaint
                        {/* </a> */} to my Lender(s) and where applicable, a
                        Financial Ombudsman Service application form.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="modal-footer p-2 d-flex gap-2 justify-content-center align-items-center border-0"
                  style={{
                    marginBottom: "2px",
                  }}
                >
                  <button
                    type="button"
                    className="btn-clear"
                    id="clear"
                    onClick={clearSign}
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    className="btn-submit"
                    id="save"
                    onClick={confirmSubmission}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ConfirmModal
        show={showModal === "confirm"}
        currentSignature={currentSignature}
        onClose={() => setShowModal("")}
        onConfirm={() => {
          submitSignature();
        }}
        retake={clearSign}
      >
        <div className="signature_canvaseWrap">
          <div
            className="signature-pad anim_bt d-lg-block web-canvas"
            ref={confirmSignatureWrapRef}
            style={{
              height: signatureWrapHeight > 200 ? 200 : signatureWrapHeight,
            }}
          >
            <SignatureCanvas
              canvasProps={{
                id: "myID",
                width: signatureWrapWidth > 300 ? 300 : signatureWrapWidth,
                height: signatureWrapHeight > 200 ? 200 : signatureWrapHeight,
              }}
              ref={confirmSignPadRef}
              onEnd={() => {
                handleChange(confirmSignPadRef);
              }}
            />
          </div>

          <Errors state={state} />
        </div>
      </ConfirmModal>

      <PrivacyModal
        show={showModal === "privacy"}
        onClose={() => setShowModal("")}
      />
    </>
  );
};
export default Signature;

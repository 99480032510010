import React, { useLayoutEffect, useState } from "react";
import CheckBox from "../../../UI/CheckBox";
import Multiselect from "multiselect-react-dropdown";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const DEFAULT_CHECKED_ID = 10;

const BasicQuestionnaireOne = ({
  className,
  slideChange,
  textHeading,
  validation,
}) => {
  const [checkedBanks, updateUserChosenBanks] = useState([DEFAULT_CHECKED_ID]);
  const [checkedOtherBanks, setOtherBankChosen] = useState("");
  const [checkedOtherBankLength, setOtherBankChosenLength] = useState(0);
  const [userInteractionStarted, setAsUserInteracted] = useState(false);
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const [showBankValidationModal, setShowBankValidation] = useState("hide");
  const [validationError, setValidationError] = useState("");

  useLayoutEffect(() => {
    if (userInteractionStarted) {
      validateCurrentForm();
    }
  }, [checkedOtherBankLength, checkedBanks]);

  const [multiOptions] = useState([
    // { name: 'Egg(Barclaycard)', id: 6 },
    { name: "Goldfish", id: 16 },
    { name: "Sainsbury's Bank", id: 24 },
    // { name: 'Marbles', id: 19 },
    { name: "Monument", id: 38 },
    // { name: 'Aqua', id: 41 },
    { name: "Argos", id: 42 },
    { name: "Alliance & Leicester (Loans)", id: 43 },
    { name: "Freemans PLC", id: 45 },
    { name: "Grattan PLC", id: 46 },
  ]);

  const otherBankOptionChanged = async (value) => {
    let selected = [];
    value.forEach((element) => {
      selected.push(element.id);
    });

    setOtherBankChosen(selected.join());
    setOtherBankChosenLength(selected.length);
    setAsUserInteracted(true);
  };

  const validateCurrentForm = () => {
    if (checkedBanks.length + checkedOtherBankLength == 0) {
      setValidationError("Please select bank");
    } else {
      setValidationError("");
    }
  };

  const setCheckedBanks = async (e) => {
    let checkedItems = checkedBanks;
    let index = checkedItems.indexOf(e.target.value);

    if (index === -1) {
      index = checkedItems.indexOf(parseInt(e.target.value));
    }

    if (e.target.checked) {
      if (index === -1) {
        checkedItems.push(e.target.value);
      }
    } else {
      if (index > -1) {
        checkedItems.splice(index, 1);
      }
    }
    updateUserChosenBanks(checkedItems);
    setAsUserInteracted(true);
    validateCurrentForm();
  };

  const proceedToNextStep = async (e) => {
    setShowBankValidation("hide");
    slideChange(e);
    GtmDataLayer("Select Bank or Lenders", "Completed");
  };

  const nextSlide = async (e) => {
    if (checkedBanks.length + checkedOtherBankLength == 0) {
      validateCurrentForm();
    } else if (checkedBanks.length + checkedOtherBankLength < 4) {
      setShowBankValidation("show");
    } else {
      slideChange(e);
      GtmDataLayer("Select Bank or Lenders", "Completed");
    }
  };

  return (
    <>
      <div id="slide-1" className={` ${className}`}>
        <h5 className="text-black text-center  fw-bold">
          Select which Banks or Lenders you may have had PPI on (Loan, Credit
          Cards, Store Cards)
        </h5>
        <fieldset className="mb-3">
          <legend> Marks & Spencer Loan Products </legend>
          <ul>
            <li className="ful-width">
              <CheckBox
                value="10"
                defaultChecked={checkedBanks.indexOf(DEFAULT_CHECKED_ID) !== -1}
                CheckBoxText="Marks & Spencer Bank"
                name="chkBankName[]"
                dataId="chkBankName10"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
          </ul>
        </fieldset>
        <fieldset>
          <legend> I also had a loan from one of these lenders: </legend>
          <ul>
            <li>
              <CheckBox
                value="30"
                CheckBoxText="Santander (Credit Card Loans)"
                name="chkBankName[]"
                dataId="chkBankName30"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="12"
                CheckBoxText="Barclaycard"
                name="chkBankName[]"
                dataId="chkBankName12"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="2"
                CheckBoxText="Lloyds"
                name="chkBankName[]"
                dataId="chkBankName2"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="3"
                CheckBoxText="MBNA"
                name="chkBankName[]"
                dataId="chkBankName3"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="5"
                CheckBoxText="Halifax Bank of Scotland"
                name="chkBankName[]"
                dataId="chkBankName5"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="13"
                CheckBoxText="First Direct"
                name="chkBankName[]"
                dataId="chkBankName13"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="8"
                CheckBoxText="Barclays"
                name="chkBankName[]"
                dataId="chkBankName8"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="7"
                CheckBoxText="Capital One"
                name="chkBankName[]"
                dataId="chkBankName7"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="21"
                CheckBoxText="HSBC Bank"
                name="chkBankName[]"
                dataId="chkBankName21"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="15"
                CheckBoxText="Black Horse"
                name="chkBankName[]"
                dataId="chkBankName15"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="18"
                CheckBoxText="Liverpool Victoria"
                name="chkBankName[]"
                dataId="chkBankName18"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="14"
                CheckBoxText="Firstplus Financial Group"
                name="chkBankName[]"
                dataId="chkBankName14"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            {/* <li>
              <CheckBox
                value="28"
                CheckBoxText="Santander (Store Cards)"
                name="chkBankName[]"
                dataId="chkBankName28"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li> */}
            <li>
              <CheckBox
                value="44"
                CheckBoxText="Littlewoods"
                name="chkBankName[]"
                dataId="chkBankName44"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="33"
                CheckBoxText="NewDay Ltd"
                name="chkBankName[]"
                dataId="chkBankName33"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="35"
                CheckBoxText="John Lewis"
                name="chkBankName[]"
                dataId="chkBankName35"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="36"
                CheckBoxText="American Express"
                name="chkBankName[]"
                dataId="chkBankName36"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="37"
                CheckBoxText="The Co-Operative Bank"
                name="chkBankName[]"
                dataId="chkBankName37"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li>
              <CheckBox
                value="17"
                CheckBoxText="J D Williams & Company Limited"
                name="chkBankName[]"
                dataId="chkBankName17"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
            <li className="ful-width">
              <CheckBox
                value="34"
                CheckBoxText="Shop Direct Finance Company Limited"
                name="chkBankName[]"
                dataId="chkBankName34"
                className="custom-checkbox"
                labelClassName={` ${checkedQuestionTwo}`}
                validation={validation()}
                onClick={setCheckedBanks}
              />
            </li>
          </ul>
        </fieldset>
        <div className="col-xl-12 col-lg-12 text-center other-bnk-c">
          <h3 className="text-start pt-3">Others</h3>
          <Multiselect
            options={multiOptions}
            selectedValues={""}
            displayValue="name"
            onSelect={(e) => {
              otherBankOptionChanged(e);
            }}
            onRemove={(e) => {
              otherBankOptionChanged(e);
            }}
          />
          <div
            className="error_msg mt-0"
            style={{ display: validationError == "" ? "none" : "block" }}
          >
            {validationError}
          </div>
        </div>
        <input
          type="hidden"
          name="otherBankChosen"
          id="otherBankChosen"
          value={checkedOtherBanks}
        />
        <div className="col-xl-12 col-lg-12 text-center">
          <input
            className="btn next-btn text-light"
            type="button"
            name="bank"
            value="Next"
            onClick={nextSlide}
          />
        </div>
      </div>
      <div
        className={`modal fade slide-one-pop ${showBankValidationModal}`}
        id="click-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div
          style={{
            marginTop: "-5%",
          }}
          className="modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="modal-body">
              <p className="text-center">
                We noticed you only selected{" "}
                {checkedBanks.length + checkedOtherBankLength} lender(s). Most
                of our clients have had PPI from 4 or more lenders throughout
                their lifetime.
              </p>
              <div className="text-center">
                <a
                  href="#add-more"
                  className="btn-pops"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setShowBankValidation("hide");
                  }}
                >
                  Yes, I'll add more
                </a>
                <a
                  href="#skip"
                  className="btn-pops"
                  id="click-1"
                  onClick={proceedToNextStep}
                  name="bank"
                >
                  No, that's all
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireOne;

import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFollowupLoad } from "../../../Hooks/useFollowupLoad";
import { queryString, urlParams } from "../../../Utility/QueryString";
import { Api } from "../../../api/Api";
import "../../../assets/followUps/dist/css/main.css";
import FLoader from "../../Includes/Layouts/FollowUps/Loader";
import SignatureSlide from "../../Includes/Layouts/FollowUps/SignatureSlide";

const RedirectionMap = {
  is_user_sign: "/followup",
  question_form_status: "/followup/questionaire",
  pcp_analyse_form_status: "/followup/analysing",
  pcp_questions_form_status: "/followup/pcp-questionnaire",
};

const FollowUps = () => {
  const userToken = urlParams.get("atp_sub2");
  const atpSub6 = urlParams.get("atp_sub6") ? urlParams.get("atp_sub6") : "FLP";
  localStorage.setItem("user_token", userToken);
  localStorage.setItem("atp_sub6", atpSub6);

  const [showLoader, setShowLoader] = useState(true);
  const [showLoad, setShowLoad] = useState("show");
  const [allFollowupData, setFollowupData] = useState({});
  const { loadFollowup } = useFollowupLoad();
  const history = useHistory();

  const setLoader = (e) => {
    setShowLoad("show");
  };
  const hideLoader = (e) => {
    setShowLoad("hide");
  };

  useEffect(() => {
    setTimeout(() => setShowLoader(false), 3000);
  }, []);

  const proceed = useCallback(
    (state) => {
      // * CASE: If user selected "No" in PCP analysis
      if (state.is_user_complete && state.pcp_analyse_form_status === 0) {
        return history.push("/thankyou");
      }

      const pending = Object.keys(RedirectionMap).filter(
        (key) => state[key] != 1
      );
      const nextStep = pending[0] ? RedirectionMap[pending[0]] : "/thankyou";

      return history.push(`${nextStep}?${queryString}`);
    },
    [history]
  );

  useEffect(() => {
    localStorage.setItem("user_token", userToken);

    (async () => {
      const response = await Api.get(
        `/api/v1/followup/get-pending-details?query_string=${queryString}`,
        {}
      );

      const { data } = response;

      if (data?.valid == 0) {
        history.push("/404");
        return;
      }

      const followupDetails = data.response.followup_data;
      setFollowupData({ flpData: data.response });

      const { user_info, pending_details } = data.response;
      localStorage.setItem("uuid", user_info?.uuid);
      localStorage.setItem("user_info", JSON.stringify(user_info));
      localStorage.setItem("pending_details", JSON.stringify(pending_details));
      localStorage.setItem("split_name", user_info?.split_name);

      if (data.status === "Success") {
        const flpResponse = await loadFollowup(
          "followup_load",
          followupDetails,
          "Split_1",
          queryString,
          "v2"
        );

        setTimeout(() => {
          hideLoader();
        }, 2000);

        const { pending_details } = data.response;

        return proceed(pending_details);
      }
    })();
  }, [history, proceed, userToken]);

  return (
    <div className="followUpSection">
      <div className="follow-up">
        <section className="followup-inner">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 l_bg">
                {showLoader ? <FLoader showLoad={showLoad} /> : ""}
              </div>
            </div>
          </div>
        </section>
      </div>

      <SignatureSlide
        allFollowupData={allFollowupData}
        userToken={userToken}
        queryString={queryString}
      />
    </div>
  );
};

export default FollowUps;

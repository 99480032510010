import React, { useContext, useEffect, useState } from "react";
import "../../../assets/followUps/scss/modules/style.scss";
import "../../../assets/followUps/scss/modules/qustion-document.scss";
import { CheckUUID } from "../../../Utility/CheckUUID";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { userInfo } from "../../../Hooks/userInfo";
import { FormData } from "../../../Utility/FormData";
import { useHistory } from "react-router-dom";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import {queryString, urlParams } from "../../../Utility/QueryString";
import { Api } from "../../../api/Api";
const FollowUpPreviewPage = () => {

   //const { isCheck, } = CheckUUID();
   //const isCheckResponse = isCheck();
   const [showLoad, setshowLoad] = useState("show");
   const [modalShowState, setModalShowState] = useState(false);
   const [slideShow, setSlideShow] = useState('state.showSlide1');

   const [userName, setUserName] = useState();
   const [address, setAddress] = useState();
   const [joineeName, setJoineeName] = useState();
   const [userDob, setUserDob] = useState();
   const [joineeDob, setJoineeDob] = useState();
   const [joineeAddress, setJoineeAdress] = useState();
   const [currentDate, setCurrentDate] = useState();
   const [userSign, setUserSign] = useState();
   const [joineeSign, setJoineeSign] = useState();
   const [partnerDate, setPartnerDate] = useState();
   const [partnerSignShow, setPartnerSignShow] = useState('hide');
   const { getUserInfo } = userInfo();
   const { visitorParameters } = useContext(VisitorParamsContext);
   const [currentUuid, setCurrentUuid] = useState();
   const [bankName, setBankName] = useState();
   const { setFormData } = FormData();
   const [userSortCode, setUserSortCode] = useState([]);
   const [userBankAccount, setUserBankAccount] = useState([]);
   const [previousAddress, setPreviousAddress] = useState();
   const history = useHistory();
   useEffect(() => {
      (async () => {
         const visitorData = visitorParameters.visitor_parameters;
         if (visitorData) {
            const uuid = visitorData.uuid;
            setCurrentUuid(uuid);
         }
      })();
   }, [visitorParameters]);




   let customUuid = '';
   let customSource = '';
   const query = new URLSearchParams(window.location.search);
   const userToken = urlParams.get("atp_sub2");
   const atpSub6 = (urlParams.get("atp_sub6")) ? urlParams.get("atp_sub6") : 'FLP';
   localStorage.setItem('user_token', userToken);
   localStorage.setItem('atp_sub6', atpSub6);

   useEffect(() => {
      localStorage.setItem('user_token', userToken);
      (async () => {
         const response = await Api.get("/api/v1/followup/get-pending-details?query_string=" + queryString, {}
         );

         if (response.data.valid == 0) {
            history.push('/404');
         }


      })();
   }, []);

   useEffect(() => {
      const formdata = JSON.parse(localStorage.getItem('formData'));
      const customPreviousData = JSON.parse(localStorage.getItem('customPreviousData'));
      const userBank = localStorage.getItem('bankName');
      let question3 = (formdata) ? formdata.question_3 : '';
      if (formdata === undefined || formdata === null || formdata == '') {

         let userInfo = localStorage.getItem('user_info');

         if (userInfo != null && userInfo != '') {
            userInfo = JSON.parse(localStorage.getItem('user_info'));
            customUuid = userInfo.uuid;
         }
         
         (async () => {
            const response = await getUserInfo(
               customUuid
            );
            const set_formdata = setFormData(
               response
            );
            let dataLength = (response.data.response).length;

            if (dataLength > 0) {
               const userName = (response.data.response[0].first_name) ? Capitalize(response.data.response[0].first_name) + ' ' + Capitalize(response.data.response[0].last_name) : '';
               const joineename = (response.data.response[0].spouses_first_name) ? Capitalize(response.data.response[0].spouses_first_name) + ' ' + Capitalize(response.data.response[0].spouses_last_name) : '';
               const usersign = (response.data.response[0].signature_image) ? response.data.response[0].signature_image : '';
               const userdob = (response.data.response[0].user_dob) ? response.data.response[0].user_dob : '';
               const joineesign = (response.data.response[0].spouse_sign) ? response.data.response[0].spouse_sign : '';
               const joineedob = (response.data.response[0].spouse_dob) ? response.data.response[0].spouse_dob : '';
               let userAddress = '';
               if (response.data.response[0].address_line1 !== '') {
                  userAddress = (response.data.response[0].address_line1) + ', ' + (response.data.response[0].town) + ', ' + (response.data.response[0].county) + ', ' + (response.data.response[0].country) + ', ' + (response.data.response[0].postcode)?.toUpperCase();
               }
               if (response.data.response[0].previous_address_city) {
                  let preAddress = ''
                  if (response.data.response[0].previous_address_line1 !== null) {
                     preAddress += response.data.response[0].previous_address_line1;
                  }
                  if (response.data.response[0].previous_address_line2 !== null) {
                     preAddress += ', ' + response.data.response[0].previous_address_line2;
                  }
                  if (response.data.response[0].previous_address_city !== null) {
                     preAddress += ', ' + response.data.response[0].previous_address_city;
                  }
                  if (response.data.response[0].previous_address_province !== null) {
                     preAddress += ', ' + response.data.response[0].previous_address_province;
                  }
                  if (response.data.response[0].previous_address_country !== null) {
                     preAddress += ', ' + response.data.response[0].previous_address_country;
                  }
                  if (response.data.response[0].previous_postcode !== null) {
                     preAddress += ', ' + (response.data.response[0].previous_postcode)?.toUpperCase();
                  }
                  //preAddress += ',' + response.data.response[0].previous_address_city + ',' + response.data.response[0].previous_address_province + ',' + response.data.response[0].previous_address_country + ',' + (response.data.response[0].previous_postcode)?.toUpperCase();
                  setPreviousAddress(preAddress);
               }
               const bankname = (response.data.response[0].bank_name) ? response.data.response[0].bank_name : null;
               setBankName(bankname);
               setUserName(userName);
               setUserSign(usersign);
               setUserDob(userdob);
               setAddress(userAddress);
               setJoineeName(joineename);
               setJoineeDob(joineedob);
               setJoineeSign(joineesign);
               if (joineename === undefined || joineename === null || joineename === '') {
                  setJoineeAdress('');

               } else {
                  setJoineeAdress(userAddress);
               }
               let timeNow = new Date().toLocaleString();
               timeNow = (timeNow.split(' ')[0]).replace(/,/g, '');
               if (joineename === undefined || joineename === null || joineename === '') {
                  setPartnerDate('');
                  setPartnerSignShow('hide');
               } else {
                  setPartnerDate(timeNow);
                  setPartnerSignShow('show');
               }
               setCurrentDate(timeNow);
            }
         })();
      } else {


         const userName = (formdata) ? Capitalize(formdata.txtFName) + ' ' + Capitalize(formdata.txtLName) : '';
         // const joineeName = (formdata) ? Capitalize(formdata.jointFName) + ' ' + Capitalize(formdata.jointLName) : '';
         const userdob = (formdata) ? formdata.DobDay + '/' + formdata.DobMonth + '/' + formdata.DobYear : '';
         const joineedob = (formdata.JointDobDay) ? formdata.JointDobDay + '/' + formdata.JointDobMonth + '/' + formdata.JointDobYear : '';
         const userSign = (formdata) ? formdata.user_sign : '';
         let userAddress = '';
         if (formdata) {
            if (formdata.txtAddress2 !== '') {
               userAddress = (formdata.txtHouseNumber + ', ' + formdata.txtAddress2 + ', ' + formdata.txtTown + ', ' + formdata.txtCounty + ', ' + formdata.txtCountry + ', ' + (formdata.txtPostCode)?.toUpperCase());
            } else {
               userAddress = (formdata.txtHouseNumber + ', ' + formdata.txtTown + ', ' + formdata.txtCounty + ', ' + formdata.txtCountry + ', ' + (formdata.txtPostCode)?.toUpperCase());
            }
            if (customPreviousData) {
               let preAddress = ''
               if (customPreviousData.previous_address_city !== '' || customPreviousData.previous_address_city !== null) {
                  if (customPreviousData.previous_address_line1 !== null) {
                     preAddress += customPreviousData.previous_address_line1;
                  }
                  //  preAddress += customPreviousData.previous_address_line1;
                  if (customPreviousData.previous_address_line2 !== '') {
                     preAddress += ', ' + customPreviousData.previous_address_line2;
                  }
                  if (customPreviousData.previous_address_city !== '') {
                     preAddress += ', ' + customPreviousData.previous_address_city;
                  }
                  if (customPreviousData.previous_address_province !== '') {
                     preAddress += ', ' + customPreviousData.previous_address_province;
                  }
                  if (customPreviousData.previous_address_country !== '') {
                     preAddress += ', ' + customPreviousData.previous_address_country;
                  }
                  if (customPreviousData.previous_postcode_1 !== '') {

                     preAddress += ', ' + (customPreviousData.previous_postcode_1)?.toUpperCase();
                  }
                  //preAddress += ',' + customPreviousData.previous_address_city + ',' + customPreviousData.previous_address_province + ',' + customPreviousData.previous_address_country + ',' + (customPreviousData.previous_postcode_1)?.toUpperCase();
                  setPreviousAddress(preAddress);
               }
            }
         }
         const partnerSign = (formdata) ? formdata.partner_sign : '';
         setBankName(userBank);
         setUserName(userName);
         setAddress(userAddress);
         setJoineeName(joineeName);
         setUserDob(userdob);
         setJoineeDob(joineedob);
         if (question3 == 5) {
            setJoineeAdress(userAddress);
            setJoineeSign(partnerSign);
         } else {
            setJoineeAdress('');
            setJoineeSign('');
         }
         setUserSign(userSign);
         let timeNow = new Date().toLocaleString();
         timeNow = (timeNow.split(' ')[0]).replace(/,/g, '');
         if (question3 == 5) {
            setPartnerDate(timeNow);
            setPartnerSignShow('show');
         } else {
            setPartnerDate('');
         }
         setCurrentDate(timeNow);
      }

      const localSortcode = localStorage.getItem('sort_code');
      if (localSortcode != undefined && localSortcode != 'null' && localSortcode != '' && localSortcode != 'Not In The List') {
         const l_SortCode = Array.from(String(localSortcode), String);
         setUserSortCode(l_SortCode);
         setModalShowState(false);

      }
      const localAccountNumber = localStorage.getItem('account_number');
      if (localAccountNumber != undefined && localAccountNumber != 'null' && localAccountNumber != '') {
         const l_AccountNumber = Array.from(String(localAccountNumber), String);
         setUserBankAccount(l_AccountNumber);
         setModalShowState(false);
      }
   }, [userName, userSign, userDob, address, joineeName, joineeDob, joineeSign, currentDate, joineeAddress, partnerDate, partnerSignShow]);

   function Capitalize(string) {
      return string.charAt(0)?.toUpperCase() + string.slice(1)?.toLowerCase();
   }
   function redirectPage() {
      history.push("/followup/ThankYou?" + queryString);
   }
   return (
      <div className="qustion-documnt">
         <section className="">
            <div className="container pdf-section">
               <div className="row">
                  <div className="col-lg-12 text-center">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td>
                                 <img src="../assets/Followups/img/logo.png" alt="" className="logo" />
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-5 headtxt">
                  </div>
               </div>
               <div className="row">
                  <div className="col-12 content_txt">
                     <h2>
                        Please read the statements below and tick the box if you are agreeable. This would help us speed up processing your claim.
                     </h2>
                     <p>
                        The claim we are making on your behalf will require a statement for the court at some point.
                     </p>
                     <p>
                        Your claim is essentially that you would not have taken the PPI if you were aware of the significant commission that was being paid to the lender for selling the policy.
                     </p>
                     <p>
                        The commission was a percentage of the PPI you paid, so this means where you paid £10 for the PPI, the cost was in actually fact £3 or even less.
                     </p>
                  </div>

                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td className="q-num">1.</td>
                              <td>
                                 <p>
                                    My date of birth is {userDob}
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="q-num">2.</td>
                              <td>
                                 <p>
                                    Although I have entered into various financial arrangements during the course of my life, I do not consider myself to be particularly literate in financial matters.
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="q-num">3.</td>
                              <td>
                                 <p>
                                    The Claim arises out of the sale of a credit product and associated Payment Protection Insurance Policy (“PPI Policy”) with the Defendant, for a credit facility.
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="q-num">4.</td>
                              <td>
                                 <p>
                                    I have been told by my solicitors, that the percentage amount which was to be paid as commission, was in excess of 50%. Therefore, if I would have been advised by the Defendant of the amount which was to be paid as commission, I would not have agreed to purchase the PPI policy. I would have strongly suspected that it was not in my best interests to purchase the PPI policy given that so little of it went towards funding the insurance itself.
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="q-num">5.</td>
                              <td>
                                 <p>
                                    Furthermore, my solicitors have informed me that it is highly likely that the Defendant operated a scheme whereby it would receive further financial benefit from the funds received from me. I understand (from my solicitors), that this is referred to as a ‘profit-share’ scheme.
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="q-num">6.</td>
                              <td>
                                 <p>
                                    I was never told by the Defendant that any profit-share scheme existed. If I had any idea about the extent of the financial benefit (including the commission and any additional profit-share benefit) that the Defendant received, then I would never have considered purchasing the PPI policy.
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="q-num">7.</td>
                              <td>
                                 <p>
                                    Further, following non-disclosure of PPI commissions and profit share, I incurred a debt for PPI sums and interest which was added to the sums owed under the agreement.
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="q-num">8.</td>
                              <td>
                                 <p>
                                    The Defendant could have communicated at any point the commission and or profit share each occasion that it sent or shared a credit statement and never did so or deliberately choose not to.
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="q-num">9.</td>
                              <td>
                                 <p>
                                    Even when the policy ended, I continued to incur economic loss as my credit balance contained compounded interest directly related to the PPI.
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="q-num">10.</td>
                              <td>
                                 <p>
                                    I understand that vulnerability factors may be taken into account in my case. The following vulnerability factors apply:
                                 </p>
                              </td>
                           </tr>
                           <tr>
                              <td>&nbsp;</td>
                              <td>
                                 <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                       <tr>
                                          <td className="q-num">a.</td>
                                          <td>
                                             <p>
                                                I was not financially sophisticated or experienced;
                                             </p>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>

            <div className="container pdf-section">
               <div className="row">
                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                              <td>
                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </td>
                           </tr>
                           <tr>
                              <td>&nbsp;</td>
                              <td>
                                 <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                       <tr>
                                          <td className="q-num">b.</td>
                                          <td>
                                             <p>
                                                I was not aware of the many factors to consider in respect of financial products (such as PPI), particularly in terms of how to assess if the product on offer is priced fairly; and
                                             </p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td className="q-num">c.</td>
                                          <td>
                                             <p>
                                                I was not aware of the existence of alternative products that may have been priced more reasonably.
                                             </p>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div className="col-12 q-col">
                     <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                           <tr>
                              <td valign="top" className="q-num">11.</td>
                              <td>
                                 <p>
                                    As a result of the above I wish to claim back the undisclosed commission from the Defendant. To this end, I am seeking to obtain Judgment for a sum which equates to a total refund of the amount which I paid in PPI premiums, contractual interest and charges, together with compensatory interest to be calculated at a rate determined by the Court, from the date of my first PPI premium payment until the date of trial, less any redress payment already paid.
                                 </p>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div className="col-12 q-col">
                     <h2 className="text-center h4 fw-bold text-black my-4">
                        <u>STATEMENT OF TRUTH</u>
                     </h2>
                     <p className="fw-bold text-black mb-4">
                        I believe that the facts stated in this witness statement are true. I understand that proceedings for contempt of court may be brought against anyone who makes, or causes to be made, a false statement in a document verified by a statement of truth without an honest belief in its truth.
                     </p>
                     <p>
                        Claimant: {userName}
                     </p>
                     <p className="py-5">
                        Signed: <img className="signImg" src={` ${userSign}`} />

                     </p>
                     <p>
                        Dated:  {currentDate}
                     </p>
                  </div>
                  <a href="#" className="button button-next animated animated-effect" onClick={redirectPage}>Verify + Confirm
                  </a>
               </div>
               <div className="clearfix"></div>
               <div className="sp-h col-12"></div>
            </div>
         </section>
         <script src="dist/js/app.js"></script>
      </div>

   );
}

export default FollowUpPreviewPage;
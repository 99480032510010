import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as EnvConstants from "../../Constants/EnvConstants";
import QueryStringContext from "../../Contexts/QueryString";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { getParameterByName } from "../../Utility/Helper";
import BasicQuestionnaireOne from "../Includes/Layouts/CL_PLVR2/BasicQuestionnaireOne";
import ContactDetails from "../Includes/Layouts/CL_PLVR2/ContactDetails";
import PersonalDetails from "../Includes/Layouts/CL_PLVR2/PersonalDetails";
import PostCode from "../Includes/Layouts/CL_PLVR2/PostCode";

const FormSplit_CL_PLVR2 = () => {
  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide",
    showSlide8: "hide",
    showSlide9: "hide",
    showSlide10: "hide",
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "bank") {
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "PersonalDetails") {
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "address") {
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "question_4") {
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "five") {
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
          };
        } else if (action.payload.clickedSlide.slide === "six") {
          return {
            ...state,
            showSlide6: "hide",
            showSlide7: "show",
          };
        } else if (action.payload.clickedSlide.slide === "address1") {
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "hide",
            showSlide9: "show",
          };
        } else if (action.payload.clickedSlide.slide === "joint_slide") {
          return {
            ...state,
            showSlide8: "hide",
            showSlide9: "show",
          };
        } else if (action.payload.clickedSlide.slide === "postcode") {
          return {
            ...state,
            showSlide9: "hide",
            showSlide10: "show",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      case 'previousSlides': {
        if (action.payload.previousSlide.slide == 'back01') {
          return {
            ...state,
            showSlide1: "show",
            showSlide2: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'back02') {
          return {
            ...state,
            showSlide2: "show",
            showSlide3: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'back03') {
          return {
            ...state,
            showSlide3: "show",
            showSlide4: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'back04') {
          return {
            ...state,
            showSlide4: "show",
            showSlide5: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'back05') {
          return {
            ...state,
            showSlide5: "show",
            showSlide6: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'back06') {
          return {
            ...state,
            showSlide6: "show",
            showSlide7: "hide"
          }
        }
        else {
          return {
            ...state
          }
        }
      }
      default: {
      }
    }
  };
  const splitForm = useRef(null);
  const scrollfrmv2div = useRef([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const { register, errors, trigger, setError, clearErrors, getValues, setFocus, setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const slideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setClickedSlide({ slide: e.target.name });
  };
  const slideChangeAddress = () => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setClickedSlide({ slide: "address" });
  };

  const previousSlideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: 'previousSlides', payload: { previousSlide } });
    }
  }, [previousSlide]);

  const formSubmit = async () => {
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    let bankList = [];
    let otherBankList = [];
    let banks = [];

    if (document.getElementsByName('chkBankName[]').length > 0) {
      document.getElementsByName('chkBankName[]').forEach(function (e) {
        if (e.checked) {
          banks.push(e.value);
          bankList.push(e.value);
        }
      });
    }

    if (document.getElementById('otherBankChosen') != null) {
      let bankValue = document.getElementById('otherBankChosen').value;
      if (bankValue != '') {
        let bankArr = bankValue.split(',');

        bankArr.forEach(element => {
          banks.push(element);
          otherBankList.push(element);
        });
      }
    }

    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }

    if (formData.addressprev1 !== "") {
      const txtUdprn = form["txtUdprnPrev"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffixPrev"].value;
      const txtPz_mailsort = form["txtPz_mailsortPrev"].value;
      const txtStreet = form["txtStreetPrev"].value;
      formData.txtUdprnPrev = txtUdprn;
      formData.txtDeliveryPointSuffixPrev = txtDeliveryPointSuffix;
      formData.txtPz_mailsortPrev = txtPz_mailsort;
      formData.txtStreetPrev = txtStreet;
    }

    formData.banks = banks;
    formData.bankList = bankList;
    formData.otherBankList = otherBankList;

    formData.page_name = 'Plevin/CL_PLVR2';
    // formData.banks = banks;
    var ivaOrBankrupt = '';
    document.getElementsByName('iva_or_bankrupt').forEach((e) => {
      if (e.checked) {
        ivaOrBankrupt = e.value;
      }
    });

    var isJointlyHeldPolicy = '';
    document.getElementsByName('jointly_held_policy').forEach((e) => {
      if (e.checked) {
        isJointlyHeldPolicy = e.value;
      }
    });

    if (form['previous_postcode'] !== undefined) {
      formData.previous_postcode = form['previous_postcode'];
    }

    formData.ivaOrBankrupt = ivaOrBankrupt;
    formData.isJointlyHeldPolicy = isJointlyHeldPolicy;

    formData.pCode_manual = form['pCode_EntryType'].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem('formData', JSON.stringify(formData));
      localStorage.setItem('queryString', queryString);
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        'split_form_submit',
        'Plevin/CL_PLVR2',
        queryString,
        visitorParameters.data
      )

      if (formSUbmitResult.data.status === "Success") {
        if (queryString != null) {

          if (queryString.indexOf("&" + field + "=") !== -1) {

            if (EnvConstants.AppConversionPixel === 'true') {
              const pid = getParameterByName(field, queryString);
              history.push("/fbpixel?split_name=Plevin/CL_PLVR2&pid=" + pid);// fb fixel firing
            } else {
              window.scrollTo(0, 0);
              history.push(
                "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
              );
            }
          } else {
            window.scrollTo(0, 0);
            history.push(
              "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        } else {
          window.scrollTo(0, 0);
          history.push(
            "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        }
      }
    }
  };

  return (
    <>
      <div className="form-bg">
        

        <div className="form-inner bg-white">
        <h4 className={`text-light text-center m-0 fw-bold ${state.showSlide2}`} id="headding-2">60% Completed</h4>
        <h4 className={`text-light text-center m-0 fw-bold ${state.showSlide3}`} id="headding-3">70% Completed</h4>
        <h4 className={`text-light text-center m-0 fw-bold ${state.showSlide4}`} id="headding-4">90% Completed</h4>
          <form
            ref={splitForm}
            name="split_form"
            id="user_form"
            method="POST"
            autoComplete="off"
          >
            <div ref={scrollfrmv2div}>
              <BasicQuestionnaireOne
                className={`form-group ${state.showSlide1}`}
                splitForm={splitForm}
                //textHeading="Are you currently enrolled in Medicare?"
                validation={register}
                slideChange={slideChange}
                getValues={getValues}
              />

              <PersonalDetails
                className={`form-group ${state.showSlide2}`}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                getValues={getValues}
                splitForm={splitForm}
                slideChange={slideChange}
                slideChangeAddress={slideChangeAddress}
                backClick={previousSlideChange}
                setError={setError}
              />

              <PostCode
                validation={register}
                validationMsg={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                setError={setError}
                className={`${state.showSlide3}`}
                getValues={getValues}
                splitForm={splitForm}
                slideChange={slideChange}
                backClick={previousSlideChange}
                setValue={setValue}
              />

              <ContactDetails
                validation={register}
                validationMsg={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                setError={setError}
                className={`${state.showSlide4}`}
                getValues={getValues}
                formSubmit={formSubmit}
                backClick={previousSlideChange}
                setFocus={setFocus}
              />
              {
                state.showSlide4 != 'show' &&
                <div className="col-xl-12 col-lg-12 text-center">
                  <h6 className="text-black fw-bold">PERSONAL INFORMATION GUARANTEE</h6>
                  <p className="text-black">We do not cold call, spam or pass on your data for marketing</p>
                </div>
              }

            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormSplit_CL_PLVR2;

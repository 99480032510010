import React from "react";
import "../../../assets/css/split_1.scss";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from '../../../Utility/FbViewPixel';
import FormSplit_1 from "../../Forms/FormSplit_1";
import Footer from "../../Includes/Layouts/Plevin/Footer";
import Header from "../../Includes/Layouts/Plevin/Header";

const Plevin = () => {
  const goToForm = () => {
      let y = document.querySelector('.form-bg').offsetTop;
      let x = document.querySelector('.form-bg').offsetLeft;
      window.scrollTo(x, y)
  };

  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="Plevin/CL_PLV1.1" />
      <FbViewPixel/>
      <div className="maninCssSection split-1">
        <Header/>
        <section className="form-section mb-3 py-3">
         <div className="container">
            <div className="row">
               <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 logo d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  <img src="/assets/img/logo.png" alt="" />
               </div>
               <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                  <FormSplit_1 />
               </div>
            </div>
         </div>
      </section>
      <section className="nowinfee-section py-5">
         <div className="container">
            <div className="row" style={{fontColor: '#636363'}}>
               <div className="col-xl-3 col-lg-3 col-md-3 text-center pound-bg">
                  <img src="/assets/img/pound.png" alt=""/>
               </div>
               <div className="col-xl-6 col-lg-6 col-md-6 text-center">
                  <h4 className="fw-bold my-3">
                     NO WIN, NO FEE
                  </h4>
                  <p className="fw-bold">
                     We work on a ‘No win, no fee’ basis, so there is nothing to pay up front and we only charge our fee if your claim is successful and you receive compensation.
                  </p>
               </div>
               <div className="col-xl-3 col-lg-3 col-md-3 text-center">
                  <ul className="p-0">
                     <li className="fw-bold text-start">
                        No Win No Fee
                     </li>
                     <li className="fw-bold text-start">
                        92% Success rate
                     </li>
                     <li className="fw-bold text-start">
                        Average claim £3580
                     </li>
                  </ul>
                  <img src="/assets/img/trst.png" alt="" className="trst-image"/>
               </div>
            </div>
         </div>
      </section>
      <section className="about-section py-5">
         <div className="container">
            <div className="col-xl-12 col-lg-12 blue-background py-4 mb-3 mobpad-adjust">
               <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-5 marginauto-adjust">
                     <img src="/assets/img/img01.jpg" alt=""/>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-7">
                     <h4 className="fw-bold text-light my-4">ABOUT US</h4>
                     <p className="text-light"> <strong><i> ClaimLion Law </i></strong>  was founded in 2010 by Negar Yazdani, a lawyer and former banker, to obtain financial redress for consumers for mis-sold financial products and since then, it has <strong>successfully collected compensation for tens of thousands of clients </strong> from banks and other companies on a range of financial products. </p>
                     <p className="text-light">Our litigation specialists, based in London, deliver an exceptional service to clients while facilitating access to justice on a <strong>‘no win, no fee’</strong> basis. </p>
                     <p className="text-light">We represent individuals against banks, insurance companies and other large companies in complex litigation. We are the leading law firm in financial mis-selling claims combining our extensive legal knowledge and financial experience to <strong>fight for compensation that is rightfully due to our clients.</strong></p>
                  </div>
               </div>
            </div>
            <div className="col-xl-12 col-lg-12 blue-background py-4 mb-3 mobpad-adjust">
               <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-5 marginauto-adjust">
                     <img src="/assets/img/img02.jpg" alt=""/>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-7">
                     <h4 className="fw-bold text-light my-4">ARE YOU ELIGIBLE TO MAKE A PPI ‘PLEVIN’ CLAIM? </h4>
                     <p className="text-light"><strong>You might be eligible for compensation if any of the below applies to you, even if you have already claimed PPI or had a PPI claim dismissed:</strong></p>
                     <p className="mb-1 text-light"> <strong>High Commission Levels</strong></p>
                     <p className="text-light">If your provider, broker or finance company were earning more than a 50% commission on your PPI policy sale?</p>
                     <p className="mb-1 text-light"><strong>Undisclosed Commission</strong></p>
                     <p className="text-light">If you were unaware of the commission being paid on your policy at the time of purchase, even if you were aware of the PPI itself?</p>
                     <p className="text-light"><strong>If you recognise either of these scenarios, then you might have a similar case to Susan Plevin and could be eligible to make a claim.</strong></p>
                     <a className="btn eligible-btn fw-bold text-light mb-4" onClick={goToForm}>Check if you are eligible</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
       <Footer/>
      </div>
    </>
  );
};

export default Plevin;

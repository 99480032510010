import { useCallback } from "react";
import { Api } from "../api/Api";

const ERROR_MAP = [
  {
    code: 0,
    message: "Please Enter Valid Working Phone Number",
  },
  {
    code: 2,
    message: "Phone Number Already Exist",
  },
  {
    code: 3,
    message: "Please Enter Valid Phone Number",
  },
];

export const usePhoneValidation = () => {
  const phoneValidation = useCallback(async (phone, uuid = null) => {
    if (!uuid) {
      const urlParams = new URLSearchParams(window.location.search);
      uuid = urlParams.get("uuid") ?? localStorage.getItem("uuid");
    }

    const getValidation = await Api.get(`api/v1/get-phone-validation`, {
      params: {
        phone,
        uuid,
      },
    });

    return getValidation;
  }, []);

  const validatePhone = useCallback(
    async (phone, errorMap = null) => {
      if (!phone) return;
      if (!errorMap) errorMap = ERROR_MAP;

      const { data } = await phoneValidation(phone);
      if (!data) return;

      return errorMap.find((e) => e.code === data?.status_code)?.message;
    },
    [phoneValidation]
  );

  return { phoneValidation, validatePhone };
};

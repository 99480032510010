import { CommonEmailValidateApi } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";

export const useEmailValidation = () => {
  const emailValidation = async (email) => {
    const getValidation = await CommonEmailValidateApi.post(
      `api/validate?ProjectCode=${EnvConstants.AppAdtopiaUPID}&Environment=${EnvConstants.AppEnv}&Email=${email}`,
      {}
    );
    return getValidation;
  };

  const validateEmail = async (email) => {
    if (!email) return;

    const { data } = await emailValidation(email);
    if (!data) return;

    if (data?.status_code === 1) return;

    return "Invalid Email Address";
  };
  return { emailValidation, validateEmail };
};

import { useCallback, useState } from "react";
import * as EnvConstants from "../Constants/EnvConstants";
import { CommonEndApi } from "../api/Api";

export const usePostCodeAddress = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getPostcodeAddress = useCallback(async (postcode) => {
    setIsLoading(true);

    const response = await CommonEndApi.get(
      "api/get-addr-list?ProjectCode=" +
        EnvConstants.AppAdtopiaUPID +
        "&Environment=" +
        EnvConstants.AppEnv +
        "&postcode=" +
        postcode,
      {}
    ).catch(function () {
      setIsLoading(false);
    });

    setIsLoading(false);

    return response;
  }, []);

  const getSplitPostcodeAddress = useCallback(async (AddressID, postcode) => {
    setIsLoading(true);

    const response = await CommonEndApi.get(
      "api/get-addr-details?ProjectCode=" +
        EnvConstants.AppAdtopiaUPID +
        "&Environment=" +
        EnvConstants.AppEnv +
        "&addressId=" +
        AddressID,
      {}
    ).catch(function () {
      setIsLoading(false);
    });

    setIsLoading(false);

    return response;
  }, []);

  return { getPostcodeAddress, getSplitPostcodeAddress, isLoading };
};

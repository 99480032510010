import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import "./App.scss";
import Analysing from "./Components/Pages/Analysing";
import EnvDocView from "./Components/Pages/EnvDocView";
import FbPixel from "./Components/Pages/FbPixel";
import HomePage from "./Components/Pages/HomePage";
import IdUpload from "./Components/Pages/IdUpload";
import NotFound from "./Components/Pages/NotFound";
import FollowUps from "./Components/Pages/PageSplit/FollowUp";
import FollowUpSignature from "./Components/Pages/PageSplit/FollowUpSignature";
import FollowUpPreviewPage from "./Components/Pages/PageSplit/PreviewPage";
import ThankYouPage from "./Components/Pages/PageSplit/ThankYouPage";
import Preview from "./Components/Pages/Preview";
import PreviewLoa from "./Components/Pages/PreviewLoa";
import Proceed from "./Components/Pages/Proceed";
import Questionnaire_4_5 from "./Components/Pages/Questionnaire_4_5";
import Questionnaire_5 from "./Components/Pages/Questionnaire_5";
import Signature from "./Components/Pages/Signature";
import Plevin from "./Components/Pages/Splits/Plevin";
import Split_CL_PLV_1_2 from "./Components/Pages/Splits/Split_CL_PLV_1_2";
import Split_CL_PLV_2_1 from "./Components/Pages/Splits/Split_CL_PLV_2_1";
import Split_CL_PLV_3_1 from "./Components/Pages/Splits/Split_CL_PLV_3_1";
import Split_CL_PLV_5_1 from "./Components/Pages/Splits/Split_CL_PLV_5_1";
import Split_CL_PLVR1 from "./Components/Pages/Splits/Split_CL_PLVR1";
import Split_CL_PLVR2 from "./Components/Pages/Splits/Split_CL_PLVR2";
import Split_CL_PLVR4 from "./Components/Pages/Splits/Split_CL_PLVR4";
import Split_CL_PLVR4_5 from "./Components/Pages/Splits/Split_CL_PLVR4_5";
import Thankyou from "./Components/Pages/Thankyou";
import Unqualified from "./Components/Pages/Unqualified";
import { QueryStringProvider } from "./Contexts/QueryString";
import { RedirectProvider } from "./Contexts/RedirectContext";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";

const DynamicQuestionnaire = lazy(() =>
  import("./Components/Pages/DynamicQuestionnaire")
);
const DynamicFollowupQuestionnaire = lazy(() =>
  import("./Components/Pages/Followup/DynamicFollowupQuestionnaire")
);

const AMCS_ADV_1 = lazy(() => {
  import("./Components/Pages/Advertorials/AMCS_ADV_1");
});
const AMCS_ADV_CONTACT = lazy(() =>
  import("./Components/Pages/Advertorials/AMCS_ADV_CONTACT")
);

const PcpQuestionnaire = lazy(() =>
  import("./Components/Pages/PcpQuestionnaire")
);
const FollowupAnalysing = lazy(() =>
  import("./Components/Pages/PageSplit/FollowupAnalysing")
);
const FollowupPcpQuestionnaire = lazy(() =>
  import("./Components/Pages/PageSplit/FollowupPcpQuestionnaire")
);

const loadSplit = (splitName) => {
  return lazy(() => import(`./Components/Pages/Splits/${splitName}`));
};

const Split_CL_PLVR5 = loadSplit("Split_CL_PLVR5");
const Split_CL_PLVR5_1 = loadSplit("Split_CL_PLVR5_1");
const Split_CL_PLVR5_2 = loadSplit("Split_CL_PLVR5_2");
const Split_CL_PLVR5_3 = loadSplit("Split_CL_PLVR5_3");

const Split_CL_PLVR6 = loadSplit("Split_CL_PLVR6");

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: "" });

  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);

  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <Suspense fallback="Loading...">
              <BrowserRouter>
                <Route exact path="/" component={HomePage} />
                <Route exact path={["/Plevin/CL_PLV1.1"]} component={Plevin} />
                <Route
                  exact
                  path={["/Plevin/CL_PLV1.2"]}
                  component={Split_CL_PLV_1_2}
                />
                <Route
                  exact
                  path={["/Plevin/CL_PLV_2.1"]}
                  component={Split_CL_PLV_2_1}
                />
                <Route
                  exact
                  path={["/Plevin/CL_PLV3.1"]}
                  component={Split_CL_PLV_3_1}
                />
                <Route
                  exact
                  path={["/Plevin/CL_PLV5.1"]}
                  component={Split_CL_PLV_5_1}
                />

                <Route
                  exact
                  path={["/Plevin/CL_PLVR1"]}
                  component={Split_CL_PLVR1}
                />
                <Route
                  exact
                  path={["/Plevin/CL_PLVR2"]}
                  component={Split_CL_PLVR2}
                />
                <Route
                  exact
                  path={["/Plevin/CL_PLVR4"]}
                  component={Split_CL_PLVR4}
                />
                <Route
                  exact
                  path={["/Plevin/CL_PLVR4.5"]}
                  component={Split_CL_PLVR4_5}
                />

                <Route
                  exact
                  path={["/Plevin/CL_PLVR5"]}
                  component={Split_CL_PLVR5}
                />
                <Route
                  exact
                  path={["/Plevin/CL_PLVR5.1"]}
                  component={Split_CL_PLVR5_1}
                />
                <Route
                  exact
                  path={["/Plevin/CL_PLVR5.2"]}
                  component={Split_CL_PLVR5_2}
                />
                <Route
                  exact
                  path={["/Plevin/CL_PLVR5.3"]}
                  component={Split_CL_PLVR5_3}
                />

                <Route
                  exact
                  path={["/Plevin/CL_PLVR6"]}
                  component={Split_CL_PLVR6}
                />

                <Route
                  exact
                  path={"/questionnaire"}
                  component={DynamicQuestionnaire}
                />
                <Route
                  exact
                  path={"/questionnaire_4.5"}
                  component={Questionnaire_4_5}
                />
                <Route
                  exact
                  path={"/questionnaire_5"}
                  component={Questionnaire_5}
                />

                <Route exact path={"/analysing"} component={Analysing} />
                <Route
                  exact
                  path={"/pcp-questionnaire"}
                  component={PcpQuestionnaire}
                />

                <Route exact path={"/followup"} component={FollowUps} />
                <Route
                  exact
                  path={"/followup_signature"}
                  component={FollowUpSignature}
                />
                <Route exact path={["/splash-page"]} component={Preview} />
                <Route exact path={"/proceed"} component={Proceed} />
                <Route exact path={"/unqualified"} component={Unqualified} />
                <Route exact path={"/signature"} component={Signature} />
                <Route exact path={["/fbpixel"]} component={FbPixel} />
                <Route exact path={"/thankyou"} component={Thankyou} />
                <Route exact path={["/id-upload"]} component={IdUpload} />
                <Route exact path={["/preview"]} component={PreviewLoa} />
                <Route exact path={["/404"]} component={NotFound} />
                <Route exact path={["/advertorials"]} component={AMCS_ADV_1} />
                <Route exact path={["/contact"]} component={AMCS_ADV_CONTACT} />

                <Route
                  exact
                  path={["/followup/questionaire"]}
                  component={DynamicFollowupQuestionnaire}
                />
                <Route
                  exact
                  path={"/followup/analysing"}
                  component={FollowupAnalysing}
                />
                <Route
                  exact
                  path={"/followup/pcp-questionnaire"}
                  component={FollowupPcpQuestionnaire}
                />
                <Route
                  exact
                  path={["/followup/preview"]}
                  component={FollowUpPreviewPage}
                />
                <Route
                  exact
                  path={["/followup/ThankYou"]}
                  component={ThankYouPage}
                />

                <Route
                  exact
                  path={["/:docType/:filename"]}
                  component={EnvDocView}
                />

                {/* <Route
                  exact
                  path={["/:docType/:filename"]}
                  component={DocView}
                /> */}
              </BrowserRouter>
            </Suspense>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;

import { useCallback } from "react";

export const useDataLayer = () => {
  const GTMDataLayer = useCallback(
    ({ question, answer = null }, log = false) => {
      const data = {
        event: "slideclick",
        question: question,
        answer: null,
      };

      console.log(data);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);

      if (log) {
        console.table(
          window.dataLayer.map(({ question, answer }) => ({ question, answer }))
        );
      }

      return window.dataLayer;
    },
    []
  );

  return { GTMDataLayer };
};

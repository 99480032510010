import React, { useReducer, useState } from "react";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import AddressTextField from "../../../UI/AddressTextField";
import HiddenField from "../../../UI/HiddenField";
import InputButton from "../../../UI/InputButton";

const initialStatePrePostcode = {
  lookUpPre: true,
  nextClickPre: false,
  checkSelectPre: false,
  getDetailsPre: [],
  getAddressPre: [],
  showManualAddressPre: "hide",
  getPostcodePre: "#",
  clickManualLinkPre: 0,
};

const PrevPostcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSuccessPre": {
      return {
        ...state,
        lookUpPre: action.payload.lookUp,
        nextClickPre: action.payload.next_click,
        getPostcodePre: action.payload.postcode,
        getAddressPre: action.payload.get_address,
        checkSelectPre: action.payload.check_select,
        showManualAddressPre: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetailsPre": {
      return {
        ...state,
        getDetailsPre: action.payload.get_details,
        showManualAddressPre: action.payload.manual_address,
      };
    }
    case "showManualDivPre": {
      return {
        ...state,
        showManualAddressPre: "show",
        lookUpPre: false,
        nextClickPre: true,
        checkSelectPre: false,
        clickManualLinkPre: action.payload.manual_click,
      };
    }
    case "resetAddressPre": {
      return {
        ...state,
        getDetailsPre: [],
        getAddressPre: [],
        showManualAddressPre: "hide",
        lookUpPre: true,
        nextClickPre: false,
        checkSelectPre: false,
      };
    }
    default:
      return state;
  }
};

function PreviousAddressModal({
  validation,
  validationMsg,
  clearErrors,
  setError,
  slideChange,
  trigger,
  className,
  backClick,
  splitForm,
  getValues,
  setValue,
}) {
  const { getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const [state, dispatch] = useReducer(
    PrevPostcodeReducer,
    initialStatePrePostcode
  );
  const [havePreviousAddress, setHavePreviousAddress] = useState();
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");

  const checkValidationPrev = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetAddressPre",
      get_address: [],
    });
    const txtPrevPostCode = await trigger("txtPrevPostCode");
    if (txtPrevPostCode) {
      await getPostcodeAddress(e.target.value)
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtPrevPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            dispatch({
              type: "validationSuccessPre",
              payload: {
                lookUpPre: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "hide",
              },
            });
          }
        })
        .catch(function () {
          setError("txtPrevPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };

  const getValue = async (e) => {
    e.preventDefault();
    // document.getElementById("address1").classList.remove("anim_ylw");
    // document.getElementById("NextButton").classList.add("anim_ylw");
    clearErrors([
      "txtHouseNumberprev",
      "txtAddress2prev",
      "txtCountyprev",
      "txtTownprev",
      "txtCountrypreb",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcodePre
      );
      dispatch({
        type: "getExtraAddressDetailsPre",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      getData.data.prevAddressId = e.target.value;
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtHouseNumberprev",
        "txtAddress2prev",
        "txtCountyprev",
        "txtTownprev",
        "txtCountryprev",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetailsPre",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidationPre = async (e) => {
    await trigger("txtPrevPostCode");
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtHouseNumberPrev").value;
    const addressLine2 = document.getElementById("txtAddress2prev").value;
    const town = document.getElementById("txtTownprev").value;
    const county = document.getElementById("txtCountyprev").value;
    const country = document.getElementById("txtCountryprev").value;
    // setPreviousAddressData(state.getDetailsPre);
    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const addressValidationPre = async (e) => {
    let errorFlag = 0;
    const txtPrevPostCode = await trigger("txtPrevPostCode");
    var manual_txtHouseNumberPrev;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;

    if (txtPrevPostCode) {
      manual_txtHouseNumberPrev = await trigger("txtHouseNumberPrev");
      txtAddress = await trigger("addressprev1");
    } else {
      document.getElementById("txtPrevPostCode").focus();
      return false;
    }
    if (manual_txtHouseNumberPrev) {
      manual_txtTown = await trigger("txtTownprev");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountryprev");
    } else {
      return false;
    }
    if (
      !txtPrevPostCode ||
      !manual_txtHouseNumberPrev ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLinkPre === 0) {
      const addressprev1 = await trigger("addressprev1");
      if (!addressprev1) {
        errorFlag = 1;
      }
    }

    if (errorFlag !== 0) return false;

    checkPostCodeEntry();
    slideChange(e);
  };

  console.log(state.getDetailsPre);

  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprnPrev"
        inputId="txtUdprn"
        inputValue={
          state.getDetailsPre !== "#" ? state.getDetailsPre.Udprn : ""
        }
      />
      <HiddenField
        name="txtDeliveryPointSuffixPrev"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetailsPre !== "#"
            ? state.getDetailsPre.deliverypointsuffix
            : ""
        }
      />
      <HiddenField
        name="txtPz_mailsortPrev"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetailsPre !== "#" ? state.getDetailsPre.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreetPrev"
        inputId="txtStreetPrev"
        inputValue={
          state.getDetailsPre.line_3 !== "#" ? state.getDetailsPre.line_3 : ""
        }
      />
    </>
  );

  return (
    <div
      className="show_box"
      id="shw-1"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className="col-lg-12 show_txt py-3 text-center mt-0">
        <div
          style={{
            display: !havePreviousAddress ? "block" : "none",
          }}
        >
          <p className="mb-1">Did you have a previous address?</p>

          <div className="my-3 radio-box">
            <input
              type="radio"
              className="radio "
              name="y2"
              id="3"
              value="Yes"
              onClick={() => {
                setHavePreviousAddress(true);
              }}
            />
            <label htmlFor="3" className="radio-sm radio yesad">
              Yes
            </label>
            <input
              type="radio"
              className="radio "
              name="address"
              id="4"
              value="No"
              onClick={(e) => {
                slideChange(e);
              }}
            />
            <label htmlFor="4" className="radio-sm radio noad">
              No
            </label>
          </div>

          <button
            name="address"
            type="button"
            className="btn btn-txt w-100 text-white p-0 skipBtn"
            onClick={(e) => {
              slideChange(e);
            }}
          >
            Skip
          </button>
        </div>

        <div
          style={{
            display: havePreviousAddress ? "block" : "none",
          }}
        >
          <h3 className="text-center text-white-50 mb-3">
            Enter your previous postcode
          </h3>
          <div className="input-section">
            <div className="form-group mb-3">
              <AddressTextField
                type="text"
                placeholder="Enter previous postcode"
                name="txtPrevPostCode"
                id="txtPrevPostCode"
                className="form-control d-block w-100"
                dataId="txtPrevPostCode"
                onBlur={checkValidationPrev}
                autoComplete="off"
                validation={validation({
                  required: "Please Enter Postcode",
                  pattern: {
                    value:
                      /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                    message: "Please Enter a Valid Postcode",
                  },
                  minLength: {
                    value: 5,
                    message: "Please Enter a Valid Postcode",
                  },
                })}
                validationMsg={
                  validationMsg.txtPrevPostCode &&
                  validationMsg.txtPrevPostCode.message
                }
              />
            </div>

            <div className="clearfix"></div>

            <div
              className="form-group col-12"
              style={
                state.lookUpPre ? { display: "block" } : { display: "none" }
              }
            >
              <div style={{ width: "100%" }} className="main_1">
                <InputButton
                  style={{ float: "none" }}
                  name="next"
                  className="btn next-btn next-btn-sm text-light w-auto"
                  value="Lookup Address"
                  btnType="button"
                  onClick={lookupValidationPre}
                />
              </div>
            </div>
            <div
              className={`form-group col-lg-12 col-12 p-0 adj_width`}
              id="currentAddressCollapse"
              style={
                state.checkSelectPre
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <AddressSelectBox
                className="form-control"
                OptionValue={state.getAddressPre}
                name="addressprev1"
                id="addressprev1"
                onChange={getValue}
                myRef={validation({ required: "Please select address" })}
                validationMsg={
                  validationMsg.addressprev1 &&
                  validationMsg.addressprev1.message
                }
              />
            </div>
            <div
              className="col-12 text-center"
              style={
                state.getDetailsPre.length === 0
                  ? { display: "block" }
                  : { display: "none" }
              }
            ></div>
            <div className="form-group mb-3"></div>
            <div className={`slide8_add row ${state.showManualAddressPre}`}>
              <div
                className={`col-lg-12 form-group required-field adj_width mb-3`}
              >
                <AddressTextField
                  type="text"
                  placeholder="Address Line 1 *"
                  name="txtHouseNumberPrev"
                  id="txtHouseNumberPrev"
                  className="form-control d-block"
                  dataId="txtHouseNumberPrev"
                  autoComplete="off"
                  readonly={true}
                  validation={validation({
                    required: "Please Enter Address Line 1 p",
                  })}
                  validationMsg={
                    validationMsg.txtHouseNumberPrev &&
                    validationMsg.txtHouseNumberPrev.message
                  }
                  value={state.getDetailsPre.line_1}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              <div className={`col-lg-12 form-group adj_width mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Address Line 2"
                  name="txtAddress2prev"
                  id="txtAddress2prev"
                  className="form-control d-block"
                  dataId="txtAddress2prev"
                  autoComplete="off"
                  readonly={true}
                  validation={validation()}
                  validationMsg=""
                  value={state.getDetailsPre.line_2}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              <div className={`col-lg-12 form-group adj_width mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="County"
                  name="txtCountyprev"
                  id="txtCountyprev"
                  className="form-control d-block"
                  dataId="txtCountyprev"
                  autoComplete="off"
                  readonly={true}
                  validation={validation()}
                  validationMsg=""
                  value={state.getDetailsPre.county}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              <div className={`col-lg-12 form-group adj_width mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Town *"
                  name="txtTownprev"
                  id="txtTownprev"
                  className="form-control d-block"
                  dataId="txtTownprev"
                  autoComplete="off"
                  readonly={true}
                  validation={validation({ required: "Please Enter Town" })}
                  validationMsg={
                    validationMsg.txtTownprev &&
                    validationMsg.txtTownprev.message
                  }
                  value={state.getDetailsPre.town}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              <div className={`col-lg-12 form-group adj_width mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Country *"
                  name="txtCountryprev"
                  id="txtCountryprev"
                  className="form-control d-block"
                  dataId="txtCountryprev"
                  autoComplete="off"
                  readonly={true}
                  validation={validation({ required: "Please Enter Country" })}
                  validationMsg={
                    validationMsg.txtCountryprev &&
                    validationMsg.txtCountryprev.message
                  }
                  value={state.getDetailsPre.country}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              {Object.keys(state.getDetailsPre).length > 0 ? (
                <HiddenFields />
              ) : null}
            </div>

            <div className="clearfix"></div>
            <div
              className="col-lg-12 col-12 text-center p-0"
              style={
                state.nextClickPre ? { display: "block" } : { display: "none" }
              }
            >
              <div className="form-group col-12">
                <InputButton
                  name="address"
                  className="btn next-btn next-btn-sm text-light"
                  id="postcode"
                  value="Next"
                  btnType="button"
                  onClick={addressValidationPre}
                  style={{ float: "none" }}
                />
              </div>
            </div>

            <div className="form-group col-12">
              <button
                name="address"
                type="button"
                className="btn btn-txt w-100 text-white p-0 skipBtn"
                onClick={(e) => {
                  setValue("txtPrevPostCode", "");
                  clearErrors("txtPrevPostCode");
                  slideChange(e);
                }}
              >
                Skip
              </button>
            </div>
          </div>
        </div>

        {havePreviousAddress === false && (
          <InputButton
            name="address"
            className="btn next-btn text-light w-100"
            id="click-2"
            value="Next"
            btnType="button"
            onClick={(e) => {
              slideChange(e);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default PreviousAddressModal;

import React, { useContext, useState } from "react";

import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import InputButton from "../../../UI/InputButton";
import RadioButtonQuestionType from "../../../UI/RadioButtonQuestionType";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const ContactDetails = ({
  validation,
  validationMsg,
  setError,
  className,
  backClick,
  trigger,
  disabled,
  getValues,
  formSubmit
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const [isBankruptValidation, setIsBankruptValidation] = useState(false);
  const [isJointAccountValidation, setIsJointAccountValidation] = useState(false);
  const [weCannotAcceptYourClaimVisibility, setWeCannotAcceptVisibility] = useState('none');

  const [jointAccountValidationMessage,
    setJointAccountValidationMessage] = useState('');
  const [bankruptValidationMessage, setBankruptValidationMessage] = useState('');

  const validateSlide4 = async (e) => {

    e.preventDefault();
    const values = getValues();
    var emailVal = await trigger("txtEmail");
    var phoneVal;
    if (emailVal) {
      phoneVal = await trigger("txtPhone");
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }

    var questionOptionValidated = true;
    if (!isBankruptValidation) {
      setBankruptValidationMessage('Please choose an option');
      questionOptionValidated = false;
    }

    if (!isJointAccountValidation) {
      setJointAccountValidationMessage('Please choose an option');
      questionOptionValidated = false;
    }

    if (isIvaOrBankrupt() == 'yes') {
      setWeCannotAcceptVisibility('block');
      return false;
    }

    if (emailVal && phoneVal && questionOptionValidated) {
      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        formSubmit();
        GtmDataLayer('Email. Phone', 'Completed');
      }
    }
  }

  const isIvaOrBankrupt = () => {
    var ivaOrBankrupt = '';
    document.getElementsByName('iva_or_bankrupt').forEach((e) => {
      if (e.checked) {
        ivaOrBankrupt = e.value;
      }
    });
    return ivaOrBankrupt;
  }

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    const email = values["txtEmail"];

    const submitBtnRef = document.getElementById("formSubmitBtn");
    if (phone !== "" && email !== "") {
      submitBtnRef?.classList.add("anim_ylw");
    } else {
      submitBtnRef?.classList.remove("anim_ylw");
    }

    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);

      const errors = [
        {
          id: 0,
          message: "Please Enter Valid Working Phone Number",
        },
        {
          id: 2,
          message: "Phone Number Already Exist",
        },
        {
          id: 3,
          message: "Please Enter Valid Phone Number",
        },
      ];

      const error = errors.find(
        (e) => e.id === getPhoneValidation.data.status_code
      );

      if (error) {
        setError("txtPhone", {
          type: "manual",
          message: error.message,
        });

        return 0;
      }

      return 1;
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values['txtEmail'];
    const phone = values['txtPhone'];

    const submitBtnRef = document.getElementById("formSubmitBtn");
    if (phone !== "" && email !== "") {
      submitBtnRef?.classList.add('anim_ylw');
    } else {
      submitBtnRef?.classList.remove('anim_ylw');
    }

    if (email !== "") {
      const getEmailValidation = await emailValidation(email)
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        setError("txtEmail", {
          type: "manual",
          message: "Invalid Email Address",
        });
      }
    }
  };

  return (

    <div id="slide-4" className={` ${className}`}>
      <fieldset>
        <legend className="dob-txt"> What's the best way to contact you about your case? </legend>
        <div className="input-group mb-3">
          <Email
            name="txtEmail"
            className="form-control textfield"
            id="txtEmail"
            placeholder="Email Address"
            onBlur={emailValidCheck}
            validation={validation({
              required: "Please Enter Valid Email Address",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid Email Address",
              },
            })}

          ></Email>
          <span className="input-group-text privacyico">
            <img src="/assets/img/privacy.png" alt="" />
          </span>
        </div>
        {validationMsg.txtEmail && (
          <span
            className="error_msg "
            id="email_err"
          >
            {validationMsg.txtEmail.message}
          </span>
        )}

        <div className="input-group mb-3">
          <Telephone
            name="txtPhone"
            id="txtphone"
            className="form-control textfield"
            placeholder="Phone Number"
            onBlur={phoneValidCheck}
            onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
            maxlength="11"
            validation={validation({
              pattern: {
                value: /^[0-9]*$/i,
                message: "Please Enter Valid Phone Number",
              },
              required: "Please Enter Phone Number",
            })}
          ></Telephone>
          <span className="input-group-text privacyico">
            <img src="/assets/img/privacy.png" alt="" />
          </span>

        </div>
        {validationMsg.txtPhone && (
          <span
            className="error_msg "
            id="phone_err"
          >
            {validationMsg.txtPhone && "Please Enter Valid Phone Number"}
          </span>
        )}
        <p className="text-danger clearmobilevalid" >
          <i>
            <strong>Mobile Phone</strong> is recommended. An automated SMS is sent when we receive news from your lender, and we’ll need to speak to you in order to finalise details
          </i>
        </p>
      </fieldset>
      <fieldset id="question_area_first">
        <legend className="dob-txt"> Are you in an IVA or currently Bankrupt </legend>
        <div className="col-xl-12 col-lg-12 text-center">

          <RadioButtonQuestionType
            id="next-2"
            key="1"
            value="no"
            name="iva_or_bankrupt"
            className="radio"
            labelName="No"
            labelClassName={`chk-btn text-light`}
            myRef={validation({ required: "Please Select an Option" })}
            onClick={() => {
              setIsBankruptValidation(true);
              setWeCannotAcceptVisibility('none');
              setBankruptValidationMessage('')
            }}
            validationMsg={
              validationMsg.iva_or_bankrupt &&
              validationMsg.iva_or_bankrupt.message
            }
          />
          <RadioButtonQuestionType
            id="next-1"
            key="2"
            value="yes"
            name="iva_or_bankrupt"
            className="radio"
            labelName="Yes"
            labelClassName={`chk-btn text-light`}
            myRef={validation({ required: "Please Select an Option" })}
            validationMsg={
              validationMsg.iva_or_bankrupt &&
              validationMsg.iva_or_bankrupt.message
            }
            onClick={() => {
              setIsBankruptValidation(true);
              setWeCannotAcceptVisibility('block');
              setBankruptValidationMessage('')
            }}
          />
          <div className="error_msg" style={{ display: bankruptValidationMessage == '' ? "none" : "block" }} >{bankruptValidationMessage}</div>
        </div>
      </fieldset>
      <fieldset>
        <legend className="dob-txt"> Has the policy been jointly held for any of the lenders you have selected? </legend>
        <div className="col-xl-12 col-lg-12 text-center" id="question_area">

          <RadioButtonQuestionType
            id="next-3"
            key="3"
            value="no"
            name="jointly_held_policy"
            className="radio"
            labelName="No"
            labelClassName={`chk-btn text-light`}
            validation={validation({
              required: "Please Enter Value",
            })}
            onClick={() => { setIsJointAccountValidation(true); setJointAccountValidationMessage('') }}

          />
          <RadioButtonQuestionType
            id="next-4"
            key="4"
            value="yes"
            name="jointly_held_policy"
            className="radio"
            labelName="Yes"
            labelClassName={`chk-btn text-light`}
            validation={validation({
              required: "Please Enter Value",
            })}
            onClick={() => { setIsJointAccountValidation(true); setJointAccountValidationMessage('') }}
          />
          <div className="error_msg" style={{ display: jointAccountValidationMessage == '' ? "none" : "block" }}>{jointAccountValidationMessage}</div>
        </div>
      </fieldset>
      <div className="col-12 d-flex">
        <input className="btn back-btn" id="back-1" name="back03" type="button" value="< Back" onClick={backClick} />
        <InputButton
          name="formSubmitBtn"
          className="btn next-btn text-light"
          id="formSubmitBtn"
          value="SUBMIT"
          btnType="submit"
          style={{ float: "none" }}
          disabled={disabled}
          onClick={validateSlide4}
        />
      </div>
      <div className="show_box" id="shw-bx2" style={{ display: weCannotAcceptYourClaimVisibility }}>
        <div className="col-lg-12 text-center show_txt">
          <p>Sorry, we cannot accept your claim</p>
          <div style={{ width: "100%", textAlign: "center" }}>
            <input className="btn bckbt" id="shwlast-bak" value=" < Back" type="button" onClick={() => { setWeCannotAcceptVisibility('none'); }} />
          </div>
        </div>
      </div>
      {/*<p className="text-center">
          <span
            className="back06 back-btn"
            name="back06"
            children="<< Previous"
            onClick={backClick}
          ></span></p> */}
    </div>
  );
};
export default ContactDetails;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { useFollowupUserSignature } from "../../../../Hooks/useFollowupUserSignature";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import Button from "../../../UI/Button";
import ConfirmModal from "./ConfirmModal";
import FFooter from "./Footer";
import PrivacyModal from "./PrivacyModal";

const initialState = {
  isCanvasEmpty: true,
  isValid: false,
  touched: false,
  isChecked: false,
  isAgreed: true,
};

const signatureSlideReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
        touched: true,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const Errors = ({ state }) => {
  if (!state || !state.touched) return <></>;

  if (state.isCanvasEmpty) {
    return (
      <div
        className="sign_msg col-12 text-center sign_msg"
        id="signatures_required"
      >
        Signature is required!
      </div>
    );
  }

  if (!state.isValid) {
    return (
      <div
        className="sign_msg col-12 text-center sign_msg"
        id="signatures_valid"
      >
        Draw valid signature!
      </div>
    );
  }

  return <></>;
};

const SignatureSlide = ({ allFollowupData, userToken, queryString }) => {
  let windowWidth = window.innerWidth;
  const widthMap = [
    [460, windowWidth - 100],
    [560, 360],
    [768, 500],
    [992, 450],
    [1200, 610],
    [1400, 730],
  ];
  let canvasWidth = widthMap.find(([width]) => windowWidth < width)?.[1] || 730;

  const signatureWrapRef = useRef(null);
  const confirmSignatureWrapRef = useRef(null);
  const [signatureWrapWidth, setCurrentSignatureWrapWidth] = useState(
    Math.max(canvasWidth, 300)
  );
  const [signatureWrapHeight, setCurrentSignatureWrapHeight] = useState(
    Math.max(canvasWidth / 2, 200)
  );

  useEffect(() => {
    function handleResize() {
      const wrapper = signatureWrapRef?.current;
      if (!wrapper) return;

      const { clientWidth, clientHeight } = wrapper;
      const height = clientWidth / 2;

      if (signatureWrapWidth) {
        const deltaWidth = signatureWrapWidth - clientWidth;
        const isSignificant = deltaWidth > 30;
        console.log("isSignificant", isSignificant, deltaWidth);
        if (!isSignificant) return;
      }

      // the difference is significant, so update the state
      setCurrentSignatureWrapWidth(clientWidth);
      setCurrentSignatureWrapHeight(height);

      // fix wrapper size (higher specificity will override inline styles)
      wrapper.style.height = `${height}px`;
      wrapper.style.minHeight = `${height}px`;
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [signatureWrapWidth]);

  const signPadRef = useRef();
  const confirmSignPadRef = useRef();

  const [showSlider, setshowSlider] = useState(false);
  const [state, dispatch] = useReducer(signatureSlideReducer, initialState);
  const { saveFollowupUserSignature, isLoading } = useFollowupUserSignature();
  const history = useHistory();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [currentPopupView, setCurrentPopupView] = useState("signature");
  const [currentSignature, setCurrentSignature] = useState("");
  console.log("===============UUId=============");

  const openModalSignature = (clickValue) => {
    switch (clickValue) {
      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      default: {
        break;
      }
    }
  };

  useEffect(() => {
    setTimeout(() => setshowSlider(true), 3000);
  }, []);

  const signPadRefs = useMemo(() => [confirmSignPadRef, signPadRef], []);

  const clearSign = () => {
    signPadRefs.forEach((ref) => {
      ref.current?.clear();
      validateSignature(ref);
    });

    setCurrentSignature("");
  };

  // sync signature
  useEffect(() => {
    console.log(signPadRefs);
    signPadRefs.forEach((ref) => {
      // console.log("sync", currentSignature);
      ref.current?.fromDataURL(currentSignature, {
        width: ref.current.getCanvas().width,
        height: ref.current.getCanvas().height,
      });
    });
  }, [
    currentSignature,
    signatureWrapHeight,
    signatureWrapWidth,
    currentPopupView,
    signPadRefs,
  ]);

  const validateSignature = useCallback((ref) => {
    const signPad = ref.current;
    if (!signPad) return;

    dispatch({
      type: "signPadChange",
      payload: { value: signPad.isEmpty() },
    });

    const rpData = signPad.toData();
    var signLength = 0;
    for (var i = 0; i < rpData.length; i++) {
      signLength += Object.keys(rpData[i]).length;
    }

    if (signLength <= 13) {
      return dispatch({
        type: "validSignature",
        payload: { value: false },
      });
    }

    dispatch({
      type: "validSignature",
      payload: { value: true },
    });
  }, []);

  const handleChange = useCallback(
    (ref) => {
      validateSignature(ref);

      const newSign = ref?.current?.getCanvas().toDataURL("image/png");
      if (newSign === currentSignature) return;
      setCurrentSignature(newSign);

      if (!currentSignature) return;
    },
    [currentSignature, validateSignature]
  );

  const confirmSubmission = useCallback(() => {
    validateSignature(signPadRef);
    if (!state.isValid) return;

    setCurrentPopupView("preview");
  }, [state.isValid, validateSignature]);

  // const handleSignaturePreview = () => {
  //   validateSignature(signPadRef);

  //   window.scrollTo(0, 200);
  //   setCurrentPopupView("loader");

  //   setTimeout(() => {
  //     setCurrentPopupView("preview");
  //   }, 2000);
  // };

  const submitSignature = async () => {
    if (!state.isValid) return;

    try {
      GtmDataLayer("Signature", "Completed");
      const response = await saveFollowupUserSignature(
        currentSignature,
        allFollowupData.flpData.followup_data,
        "followup_user_signature"
      );

      const formdata = JSON.parse(localStorage.getItem("formData"));
      if (formdata === undefined || formdata === null || formdata === "") {
        localStorage.setItem("formData", JSON.stringify(formdata));
      } else {
        formdata.user_sign = currentSignature;
        localStorage.setItem("formData", JSON.stringify(formdata));
      }
      if (response.data.status === "Success") {
        history.push("/followup/questionaire?" + queryString);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <>
      {!!showSlider && (
        <div id="myDiv2" className="TMS_sign slide2">
          <section className="signature-section animated fadeInUp">
            <div className="container">
              <div className="row ">
                <div className="offset-lg-2 col-lg-8 col-12 sign-question Larger shadow">
                  <h2>
                    Finally, we need your electronic signature below to proceed.
                    Please note that your electronic signature will be included
                    on all forms we submit to your lender
                  </h2>
                  <div className="offset-lg-2 col-lg-8 col-12">
                    <ul>
                      <li>Sign using your finger, mouse or stylus</li>
                      <li>Keep it fully contained within the box</li>
                      <li>Ensure it is a true likeness of your signature</li>
                    </ul>
                  </div>
                  <div className="sign-div  py-3 px-3">
                    <div className="mb-3">
                      <div className="signature_canvaseWrap">
                        <div
                          className="signature-pad anim_bt d-lg-block web-canvas"
                          ref={signatureWrapRef}
                          style={{
                            width: "fit-content",
                          }}
                        >
                          <SignatureCanvas
                            canvasProps={{
                              id: "myID",
                              width: signatureWrapWidth,
                              height: signatureWrapHeight,
                            }}
                            ref={signPadRef}
                            onEnd={() => handleChange(signPadRef)}
                          />
                        </div>

                        <Errors state={state} />
                      </div>
                    </div>
                    <div className="form-check my-3 p-0">
                      <label
                        className="form-check-label sign-custom-control-label"
                        htmlFor="acceptterms"
                        style={{ padding: "0px" }}
                      >
                        <label className="custom-control-labels mb-2">
                          By signing and by pressing “Submit” below, you agree
                          to ClaimLion's
                          <a
                            href="#privacy"
                            data-bs-toggle="modal"
                            data-bs-target="#privacy"
                            style={{ cursor: "pointer" }}
                            onClick={() => openModalSignature("showPrivacy")}
                          >
                            {" "}
                            Privacy Policy
                          </a>
                          and that we may use your signature on the
                          <a
                            target="_blank"
                            href="/assets/LOA/LOA.pdf"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            Letter of Authority
                          </a>
                          that we send to your bank. A copy of the
                          <a
                            href="#privacy"
                            data-bs-toggle="modal"
                            data-bs-target="#privacy"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            Privacy Policy
                          </a>
                          ,
                          <a
                            target="_blank"
                            href="/assets/LOA/LOA.pdf"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            Letter of Authority
                          </a>
                          ,
                          <a
                            target="_blank"
                            href="/assets/WS/Witness-Statement.pdf"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            Witness Statement
                          </a>{" "}
                          and
                          <a
                            target="_blank"
                            href="/assets/ST/Statement-of-Truth.pdf"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            Statement of Truth
                          </a>
                          will be sent to you via email.
                        </label>
                      </label>
                    </div>

                    <Button
                      type="button"
                      className="btn btn-clear"
                      id=""
                      onClick={clearSign}
                      buttonText="Clear"
                    />
                    <Button
                      type="button"
                      className="btn btn-submit"
                      id=""
                      onClick={confirmSubmission}
                      buttonText="Submit"
                    />
                  </div>
                </div>
              </div>
            </div>

            <ConfirmModal
              show={currentPopupView !== "signature"}
              currentSignature={currentSignature}
              onClose={() => setCurrentPopupView("signature")}
              onConfirm={() => {
                submitSignature();
              }}
              retake={clearSign}
            >
              <div className="signature_canvaseWrap">
                <div
                  className="signature-pad anim_bt d-lg-block web-canvas"
                  ref={confirmSignatureWrapRef}
                  style={{
                    width: "fit-content",
                    height:
                      signatureWrapHeight > 200 ? 200 : signatureWrapHeight,
                  }}
                >
                  <SignatureCanvas
                    canvasProps={{
                      id: "myID",
                      width:
                        signatureWrapWidth > 300 ? 300 : signatureWrapWidth,
                      height:
                        signatureWrapHeight > 200 ? 200 : signatureWrapHeight,
                    }}
                    ref={confirmSignPadRef}
                    onEnd={() => {
                      handleChange(confirmSignPadRef);
                    }}
                  />
                </div>

                <Errors state={state} />
              </div>
            </ConfirmModal>

            <PrivacyModal
              show={showPrivacy}
              onClose={() => openModalSignature("exitPrivacy")}
            />
          </section>
          <FFooter />
        </div>
      )}
    </>
  );
};

export default SignatureSlide;

import React from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const QuestionnaireWrapper = ({
  questArr,
  questId,
  reff,
  labelclicked,
  style = {},
  optionStyle = {},
}) => {
  const questAnswers = questArr.answers.map((answer, index) => {
    return (
      <RadioButtonQuest
        key={answer.value}
        className="radio"
        labelName={answer.label}
        labelClassName="br radioa"
        labelCountString={String.fromCharCode(65 + index)}
        onClick={labelclicked}
        value={answer.value}
        name={questArr.questionId}
        id={`q-${questArr.questionId}-${answer.value}`}
        style={optionStyle}
      />
    );
  });

  return (
    <div className="radio-box question -animated -fadeInUp" ref={reff}>
      <h4>{questArr.question}</h4>
      <div className="form-group">
        <div className="" id="question_group_60" style={style}>
          {questAnswers}
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireWrapper;

import React from "react";
import Modal from "react-bootstrap/Modal";

function LoaderModal({ show = true, ...props }) {
  return (
    <Modal
      show={show}
      size="sm"
      align="center"
      className="modal footer-modals fade"
      centered
      {...props}
    >
      <Modal.Body>
        <img src="/assets/img/review.gif" alt="Loader" className="w-100" />
      </Modal.Body>
    </Modal>
  );
}

export default LoaderModal;

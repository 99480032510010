import React, { useCallback, useEffect, useState } from "react";

const VehicleRegNumberInput = ({
  questArr,
  reff,
  labelclicked,
  vehicleCount = 1,
}) => {
  const { questionId } = questArr;
  const [vehicleRegNos, setVehicleRegNos] = useState([]);
  const [error, setError] = useState("");

  const validatePattern = useCallback((regNos) => {
    // RegExp from: https://gist.github.com/danielrbradley/7567269
    const regEx = new RegExp(
      "(^[A-Z]{2}[0-9]{2}s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)",
      "i"
    );

    const isValid = regNos.every((regNo) => regEx.test(regNo));
    console.log("isValid", regNos, isValid);
    if (!isValid) {
      setError("Please enter valid vehicle registration numbers");
    } else {
      setError("");
    }

    return isValid;
  }, []);

  const handleClick = useCallback(
    (e) => {
      /*if (vehicleRegNos.length < Math.min(vehicleCount, 4)) {
        setError("Please enter all vehicle registration numbers");
        return;
      }*/

      if (vehicleRegNos.length < 1) {
        setError("Please enter vehicle registration number");
        return;
      }

      const isValid = validatePattern(vehicleRegNos);
      if (!isValid) return;

      labelclicked(e);
    },
    [validatePattern, vehicleRegNos, labelclicked]
  );

  useEffect(() => {
    validatePattern(vehicleRegNos);
  }, [validatePattern, vehicleRegNos]);

  const inputCount =
    vehicleCount === Infinity
      ? Math.max(vehicleRegNos.filter((val) => !!val).length + 1, 4) // Minimum 4 inputs
      : Math.min(vehicleCount, 4); // Maximum 4 inputs

  return (
    <div className="radio-box question animated fadeInUp" ref={reff}>
      <h4>{questArr?.question ?? "Enter vehicle registration numbers:"}</h4>

      {Array(inputCount)
        .fill(0)
        .map((_, index) => (
          <div key={index} className="form-group" ref={reff}>
            <div className="" id={`question_group_60_${index}`}>
              <label
                className="br radioa overflow-hidden"
                htmlFor="question_id_60"
              >
                <span className="line-break-span">{index + 1}</span>
                <input
                  onChange={(e) => {
                    setError("");
                    const { value } = e.target;
                    const newArr = [...vehicleRegNos];
                    newArr[index] = value;
                    setVehicleRegNos(newArr);
                  }}
                  value={vehicleRegNos[index] ?? ""}
                  type="text"
                  className="from-control w-100 border-0 px-3"
                  placeholder="Enter Reg. No."
                />
              </label>
            </div>
          </div>
        ))}

      {error && <p className="text-danger">{error}</p>}

      <button
        type="button"
        onClick={handleClick}
        data-ans-id={""}
        data-quest-id={questionId}
        data-input-answer={vehicleRegNos.join(",")}
        className="btn btn-primary px-5 mt-2 ml-auto"
      >
        Submit
      </button>
    </div>
  );
};

export default VehicleRegNumberInput;

import React from "react";
import Modal from "react-bootstrap/Modal";

function PrivacyModal({ show = true, onClose = () => {}, ...props }) {
  return (
    <Modal
      show={show}
      size="lg"
      id="privacy"
      className="modal footer-modals fade"
      centered
      onHide={onClose}
      {...props}
    >
      <Modal.Header closeButton>
        <h5 className="modal-title">
          <b>PRIVACY POLICY</b>
        </h5>
      </Modal.Header>
      <Modal.Body>
        <h6>
          <b>1 About Us</b>
        </h6>
        <p>
          ClaimLion Law is a data controller and is committed to protecting your
          privacy and takes its responsibilities regarding the security of user
          information very seriously. This privacy statement sets out how
          ClaimLion Law complies with both UK and European Union data protection
          requirements. We have structured our website so that you can visit
          without identifying yourself or revealing any personal information.
          Once you choose to provide us with any information by which you can be
          identified, then you can be assured that it will only be used in
          accordance with this Privacy Notice.
        </p>

        <p>
          This website and our services are not intended for children and we do
          not knowingly collect data relating to children.
        </p>

        <p>
          If you have any queries about the policy, please get in touch with us
          using legalclaims@claimlionlaw.com or write to us at the address below
          and we will do our best to answer your questions.
        </p>

        <p>
          Data Protection Officebr
          <br />
          ClaimLion Law
          <br />
          Fulton Road
          <br />
          London
          <br />
          HA9 0NU
        </p>

        <p>This privacy policy was last updated on 24th May 2018.</p>

        <h6>
          <b>2 What is Personal data?</b>
        </h6>

        <p>
          Under the EU’s General Data Protection Regulation (GDPR) personal data
          is defined as: “any information relating to an identified or
          identifiable natural person (‘data subject’); an identifiable natural
          person is one who can be identified, directly or indirectly, in
          particular by reference to an identifier such as a name, an
          identification number, location data, an online identifier or to one
          or more factors specific to the physical, physiological, genetic,
          mental, economic, cultural or social identity of that natural person”.
        </p>

        <h6>
          <b>3 How we use your information</b>
        </h6>

        <p>
          This privacy notice tells you how we, ClaimLion Law, will collect and
          use your personal data for example:
        </p>
        <ul>
          <li>To contact you in response to a specific enquiry,</li>
          <li>
            To provide our products and services that you request from us,
          </li>
          <li>
            Send you transactional communications via the contact details you
            have provided to us during our services;
          </li>
          <li>
            Contact third parties on your behalf, with your specific
            instruction;
          </li>
          <li>
            Send you email notifications which you have specifically requested;
          </li>
          <li>To personalise the website for you,</li>
          <li>
            To send you promotional emails and mailings about ClaimLion Law
            products, services, offers and other things that we think may be
            relevant to you,
          </li>
          <li>
            Operate and manage your account and manage any application,
            agreement or correspondence you may have with us;
          </li>
          <li>Carry out, monitor and analyse our business;</li>
          <li>
            To identify, prevent, detect or tackle fraud, money laundering,
            terrorism and other crimes,
          </li>
          <li>
            To contact you via email or telephone for market research reasons
          </li>
          <li>
            To form a view of you as an individual and to identify, develop or
            improve products, that may be of interest to you.
          </li>
          <li>
            For audits, regulatory purposes, legal obligations, and compliance
            with industry standards
          </li>
          <li>
            Perform other administrative and operational purposes including the
            testing of systems
          </li>
          <li>
            Please note that we will not under any circumstances sell or share
            your data with third party marketing companies without your consent.
          </li>
        </ul>

        <h6>
          <b>4 Why do we collect and store personal data?</b>
        </h6>

        <p>
          We need to collect personal data in order for us to provide you with a
          service, to answer enquires about our services and to maintain our
          records. In any event, we are committed to ensuring that the
          information we collect, and use is appropriate for this purpose, and
          does not constitute an invasion of your privacy.
        </p>

        <h6>
          <b>5 Personal data categories we collect</b>
        </h6>

        <p>
          We may collect, use, store and transfer different kinds of personal
          data about you which we have categorised as follows:
        </p>

        <h6>
          <b>Identity Data</b>
        </h6>
        <p>
          This includes, first name, maiden name, last name, marital status,
          title, date of birth and gender
        </p>

        <h6>
          <b>Contact Data</b>
        </h6>
        <p>This includes, email address and telephone numbers.</p>

        <h6>
          <b>Case Data</b>
        </h6>
        <p>
          This includes information relevant to your case such as your
          employment status and contractual employment cover such as sick pay.
        </p>

        <h6>
          <b>Financial Data</b>
        </h6>
        <p>This includes, bank account information and payment details.</p>

        <h6>
          <b>Compliance Data</b>
        </h6>
        <p>
          This includes, recorded calls for quality checks and staff training.
          Such recordings may also be used to help us combat fraud.
        </p>

        <h6>
          <b>Technical Data</b>
        </h6>
        <p>
          This includes, internet protocol (IP) address, browser type and
          version, time zone setting and location, browser plug-in types and
          versions, operating system and platform and other technology on the
          devices you use to access this website.
        </p>

        <h6>
          <b>Usage Data</b>
        </h6>
        <p>
          This includes, information about how you use our website, products and
          services.
        </p>

        <h6>
          <b>Marketing and Communications Data</b>
        </h6>
        <p>
          This includes, your preferences in receiving marketing from us and
          your communication preferences.
        </p>

        <h6>
          <b>Aggregated Data</b>
        </h6>
        <p>
          This includes statistical or demographic data for any purpose.
          Aggregated Data may be derived from your personal data but is not
          considered personal data in law as this data does not directly or
          indirectly reveal your identity. For example, we may aggregate your
          Usage Data to calculate the percentage of users accessing a specific
          website feature. However, if we combine or connect Aggregated Data
          with your personal data so that it can directly or indirectly identify
          you, we treat the combined data as personal data which will be used in
          accordance with this privacy notice.
        </p>

        <h6>
          <b>Special Categories of Personal Data</b>
        </h6>
        <p>
          This includes health and vulnerability related data that you may
          voluntarily share with us during the fulfilment of our services to
          you. We will always ask for your explicit consent to record and share
          Special Category Data.
        </p>

        <h6>
          <b>6 How we might collect personal data</b>
        </h6>
        <p>
          We use different methods to collect data from and about you as
          follows:
        </p>

        <h6>
          <b>
            We use different methods to collect data from and about you as
            follows:
          </b>
        </h6>
        <p>
          You may give us your Identity, Contact and Financial Data by filling
          in forms or by corresponding with us by post, phone, email or
          otherwise. This includes personal data you provide when you:
        </p>
        <ul>
          <li>apply for our products or services;</li>
          <li>request marketing to be sent to you; or</li>
          <li>give us some feedback.</li>
        </ul>

        <h6>
          <b>Collected automatically: –</b>
        </h6>
        <p>
          As you interact with our website, we may automatically collect
          Technical Data about your equipment, browsing actions and patterns. We
          collect this personal data by using cookies, server logs and other
          similar technologies. We may also receive Technical Data about you if
          you visit other websites employing our cookies. Please see our cookie
          policy for further details.
        </p>

        <h6>
          <b>Provided by third parties or publicly available sources: –</b>
        </h6>
        <p>
          We may receive personal data about you from various third parties as
          set out below:
        </p>
        <ul>
          <li>
            Technical Data from analytics providers such as Google based outside
            the EU;
          </li>
          <li>
            Identity and Contact Data from publicly available sources, including
            Companies House and the Electoral Register based inside the EU.
          </li>
        </ul>

        <h6>
          <b>7 Processing purpose and our legal basis</b>
        </h6>
        <p>
          We will always have a legal basis for processing personal data and we
          have methodically assessed our purposes and legal bases.
        </p>
        <p>
          Our legal basis for processing your information is most commonly in
          line with our contractual obligations to fulfil the services and
          products you request from us.
        </p>
        <p>
          As a Regulated company we are audited and held to high standards for
          the services and products we offer. Therefore, we may be legally
          obligated to process personal data during regulatory audits.
        </p>
        <p>
          In the process of delivering our services, there may be legitimate
          interests that we pursue, which we have tested to ensure that those
          interests are balanced, appropriate and we have considered any
          potential impact on you (both positive and negative) and your rights
          before we process your personal data for our legitimate interests. We
          do not use your personal data for activities where our interests are
          overridden by the impact on you (unless we have your consent or are
          otherwise required or permitted to by law). If you have any questions
          about the legal basis for our processing activities, please feel free
          to get in touch with our Data Protection Office.
        </p>

        <h6>
          <b>8 Recipients of your personal data</b>
        </h6>
        <p>
          During the course of providing the services that you request from us,
          we may share your information with our processing partners, known as
          recipients and data processors.
        </p>
        <p>
          We conduct due diligence with both recipients and data processors
          around the areas of their data security protocols, data protection
          policies and we have strict contracts in place that govern how they
          process your information.
        </p>

        <h6>
          <b>IT Support</b>
        </h6>
        <p>
          We use Backbone Connect Limited for our IT support, infrastructure
          services and data backups.
        </p>
        <p>
          Backbone Connect Limited is a company incorporated in England and
          Wales. Registration number 06537854, having its registered office at
          10 Dominion Street, London, EC2M 8EF.
        </p>

        <h6>
          <b>Email Delivery</b>
        </h6>
        <p>
          When emailing our customers with service updates we use Mailchimp to
          help us with this.
        </p>
        <p>
          Mailchimp: The Rocket Science Group LLC d/b/a MailChimp, 675 Ponce de
          Leon Ave, NE, Suite 5000, Atlanta, GA 30308 USA
        </p>

        <h6>
          <b>Data Backups</b>
        </h6>
        <p>
          We securely store call recordings with Pebbletree Limited trading as
          Soho66.
          <br />
          Pebbletree Limited trading as Soho66, Pebbletree Limited registered in
          England with the company registration number 05587730 having its
          registered office at, Suite 1E, Liberty Way, Sunderland, SR6 0QA 3rd
          Floor
        </p>

        <h6>
          <b>Third parties</b>
        </h6>
        <p>
          Third parties to whom we may choose to sell, transfer, or merge parts
          of our business or our assets. Alternatively, we may seek to acquire
          other businesses or merge with them. If a change happens to our
          business, then the new owners may use your personal data in the same
          way as set out in this privacy notice.
        </p>

        <h6>
          <b>9 When we may have to disclose your personal data</b>
        </h6>
        <p>
          We may have to disclose your personal data with other third parties as
          set out below. These organisations or bodies will not use your
          information to contact you. These third parties will be subject to
          obligations to process your personal information in compliance with
          the same safeguards that we deploy.
        </p>

        <h6>
          <b>HM Revenue & Customs</b>
        </h6>
        <p>We’re required to disclose certain data with the HMRC.</p>

        <h6>
          <b>Solicitors Regulation Authority</b>
        </h6>
        <p>
          We’re regulated by the SRA, so we may have to disclose small amounts
          of data with them for auditing purposes.
        </p>

        <h6>
          <b>Compliance Consultants</b>
        </h6>
        <p>
          Compliance Consultants and other like services acting as processors,
          based in the United Kingdom who require reporting of processing
          activities in certain legal and compliance circumstances.
        </p>

        <h6>
          <b>11 Making sure your data is secure</b>
        </h6>
        <p>
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality.
        </p>
        <p>
          We have put in place procedures to deal with any suspected personal
          data breach and will notify you and any applicable regulator of a
          breach where we are legally required to do so.
        </p>

        <h6>
          <b>12 How long do we keep data?</b>
        </h6>
        <p>
          We will only retain your personal data for as long as necessary to
          fulfil the purposes we collected it for, including for the purposes of
          satisfying any legal, accounting, or reporting requirements.
        </p>
        <p>
          To determine the appropriate retention period for personal data, we
          consider the amount, nature, and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal requirements.
        </p>
        <p>
          Details of retention periods for different aspects of your personal
          data are available and you can request more details of that by
          contacting our Data Protection Office.
        </p>
        <p>
          By law we have to keep certain information about our customers and
          this data will be held solely and securely for those legal purposes.
        </p>

        <h6>
          <b>14 Your rights as a data subject</b>
        </h6>
        <p>
          At any point while we are in possession of or processing your personal
          data, you, the data subject, have the following rights:
        </p>

        <ul>
          <li>
            Right of access – you have the right to request a copy of the
            information that we hold about you.
          </li>
          <li>
            Right of rectification – you have a right to correct data that we
            hold about you that is inaccurate or incomplete.
          </li>
          <li>
            Right to be forgotten – in certain circumstances you can ask for the
            data we hold about you to be erased from our records.
          </li>
          <li>
            Right to restriction of processing – where certain conditions apply
            to have a right to restrict the processing.
          </li>
          <li>
            Right of portability – you have the right to have the data we hold
            about you transferred to another organisation.
          </li>
          <li>
            Right to object – you have the right to object to certain types of
            processing such as direct marketing.
          </li>
          <li>
            Right to object to automated processing, including profiling – you
            also have the right to be subject to the legal effects of automated
            processing or profiling.
          </li>
          <li>
            Right to judicial review: in the event that ClaimLion Law refuses
            your request under rights of access, we will provide you with a
            reason as to why. You have the right to complain and we have
            provided a specific section on this below.
          </li>
        </ul>

        <p>
          All of the above requests will be forwarded on should there be a third
          party involved in the processing of your personal data.
        </p>

        <h6>
          <b>15 Requesting access to my data</b>
        </h6>
        <p>
          ClaimLion Law at your request, can confirm what information we hold
          about you and how it is processed. If ClaimLion Law does hold personal
          data about you, you can request the following information:
        </p>

        <ul>
          <li>
            Identity and the contact details of the person or organisation that
            has determined how and why to process your data.
          </li>
          <li>
            Contact details of the data protection officer, where applicable.
          </li>
          <li>
            The purpose of the processing as well as the legal basis for
            processing.
          </li>
          <li>
            If the processing is based on the legitimate interests of ClaimLion
            Law or a third party, information about those interests.
          </li>
          <li>
            The categories of personal data collected, stored and processed.
          </li>
          <li>
            Recipient(s) or categories of recipients that the data is/will be
            disclosed to.
          </li>
          <li>
            If we intend to transfer the personal data to a third country or
            international organisation, information about how we ensure this is
            done securely. The EU has approved sending personal data to some
            countries because they meet a minimum standard of data protection.
            In other cases, we will ensure there are specific measures in place
            to secure your information.
          </li>
          <li>How long the data will be stored?</li>
          <li>
            Details of your rights to correct, erase, restrict or object to such
            processing.
          </li>
          <li>Information about your right to withdraw consent at any time.</li>
          <li>How to lodge a complaint with the supervisory authority?</li>
          <li>
            Whether the provision of personal data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract, as
            well as whether you are obliged to provide the personal data and the
            possible consequences of failing to provide such data.
          </li>
          <li>
            The source of personal data if it wasn’t collected directly from
            you.
          </li>
          <li>
            Any details and information of automated decision making, such as
            profiling, and any meaningful information about the logic involved,
            as well as the significance and expected consequences of such
            processing.
          </li>
        </ul>

        <h6>
          <b>
            What forms of ID will I need to provide in order to access this?
          </b>
        </h6>
        <p>
          ClaimLion Law accepts copies of the following forms of ID when
          information on your personal data is requested: Passport, driving
          licence, birth certificate, utility bill from last 3 months.
        </p>

        <h6>
          <b>Contact details of the Data Protection Officer (DPO):</b>
        </h6>
        <p>
          Data Protection Office
          <br />
          ClaimLion Law
          <br />
          Fulton Road
          <br />
          London
          <br />
          HA9 0NU
          <br />
          Email: legalclaims@claimlionlaw.com
          <br />
          Telephone: 0207 112 8616
        </p>

        <h6>
          <b>16 When things don’t go as planned</b>
        </h6>
        <p>
          In the event that you wish to make a complaint about how your personal
          data is being processed by ClaimLion Law or third parties, or how your
          complaint has been handled, you have the right to lodge a complaint
          directly with the supervisory authority and ClaimLion Law’s data
          protection representatives Data Protection Officer (DPO).
        </p>
        <p>The details for each of these contacts are:</p>

        <h6>
          <b>Supervisory authority contact details</b>
        </h6>
        <p>
          Supervisory authority
          <br />
          ClaimLion Law
          <br />
          Fulton Road
          <br />
          London
          <br />
          HA9 0NU
          <br />
          Email: legalclaims@claimlionlaw.com
          <br />
          Telephone: 0800 599 9993
        </p>
        <p>
          Data Protection Office
          <br />
          ClaimLion Law
          <br />
          Fulton Road
          <br />
          London
          <br />
          HA9 0NU
          <br />
          Email: legalclaims@claimlionlaw.com
          <br />
          Telephone: 0207 112 8616
        </p>
        <p>
          If you have any queries about the policy, please get in touch with us
          using legalclaims@claimlionlaw.com or write to us at the address below
          and we will do our best to answer your questions.
        </p>
        <p>
          Data Protection Office
          <br />
          ClaimLion Law
          <br />
          Fulton Road
          <br />
          London
          <br />
          HA9 0NU
          <br />
        </p>
        <p>This privacy policy was last updated on 24th May 2018.</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-primary btn-block"
          onClick={onClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default PrivacyModal;

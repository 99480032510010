import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppApiUrl } from "../../Constants/EnvConstants";

const ctrlShiftKey = (e, keyCode) => {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
};

const EnvDocView = () => {
  const { docType, filename } = useParams();
  const [doc, setDoc] = useState();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");

    if (!docType || !filename || !token) return;

    if (docType === "Plevin") return;

    console.log("Hello from DocView", { docType, filename });

    const apiRoute = `${AppApiUrl}/${docType}/${filename}?token=${token}`;
    console.log(apiRoute);
    setDoc(apiRoute);
  }, [docType, filename]);

  useEffect(() => {
    // Disable right-click
    document.addEventListener("contextmenu", (e) => e.preventDefault());

    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        e.keyCode === 123 ||
        ctrlShiftKey(e, "I") ||
        ctrlShiftKey(e, "J") ||
        ctrlShiftKey(e, "C") ||
        (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
      )
        return false;
    };
  }, []);

  if (!doc) return <></>;

  return (
    <>
      <object
        width="100%"
        height="800"
        data={doc}
        type="application/pdf"
        aria-label="Doc preview"
      ></object>
    </>
  );
};

export default EnvDocView;

import React,{ useState, useEffect } from "react"
const FLoader = ({ showLoad }) =>{

    const [showLoader, setShowLoader] = useState();
    useEffect(() => {
        setTimeout(() => setShowLoader(false), 3000);
    }, []);
    return(
        <>
        
        <div id="myDiv" className={`space text-center ${showLoad}`}>
        <img src="/assets/Followup/img/review.gif" alt="" />
        <h3>Analysing your application....</h3>
        </div>
        </>

    );
}
export default FLoader;






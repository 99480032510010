import React, { useState } from 'react';
import { DobYear, DobMonth, DobDay } from '../Constants/Constants';
import SelectBox from '../Components/UI/SelectBox';

const DateOfBirth = ({ textDob, validation, validationMsg }) => {
    const [finalError, setFinalError] = useState('');
    const dateValidate = () => {
        let DobDay = document.getElementById('DobDay').value;
        let DobMonth = document.getElementById('DobMonth').value;
        let DobYear = document.getElementById('DobYear').value;

        let maxNoOfDays = {
            'January': 31,
            'February': 29,
            'March': 31,
            'April': 30,
            'May': 31,
            'June': 30,
            'July': 31,
            'August': 31,
            'September': 30,
            'October': 31,
            'November': 30,
            'December': 31
        };

        if (DobMonth != '' && DobDay != '') {
            let maxNumber = maxNoOfDays[DobMonth];
            
            if (DobDay > maxNumber) {
                setFinalError('Please select a validate Date');
                return false;
            }

            if (DobYear != '' && DobMonth == 'February' && DobDay == 29) {
                let isLeapYear = (DobYear % 4 == 0) ? true : false;

                if (!isLeapYear) {
                    setFinalError('Please select a validate Date');
                    return false;
                }
            }
        }        
        setFinalError('');
        return true;
    };

    return (
        <div className="form-group col-12">
            <fieldset className="anim_ylw">
                <legend className="dob-txt">{textDob}</legend>
                <div className="row m-0">
                    <div className="col-12 col-sm-3">
                        <div className="mb-3">

                            <SelectBox
                                className="form-select textfield"
                                OptionValue={DobDay}
                                name="DobDay"
                                id="DobDay"
                                onChange={dateValidate}
                                myRef={validation({
                                    required: "Please Select Date",
                                    validate: dateValidate
                                })}
                                validationMsg={validationMsg.DobDay && validationMsg.DobDay.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobDay_err" className="error_msg"></span> */}

                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="mb-3">
                            <SelectBox
                                className="form-select textfield"
                                OptionValue={DobMonth}
                                name="DobMonth"
                                id="DobMonth"
                                onChange={dateValidate}
                                myRef={validation({
                                    required: "Please Select Month"
                                })}
                                validationMsg={validationMsg.DobMonth && validationMsg.DobMonth.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobMonth_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="mb-3">

                            <SelectBox
                                className="form-select textfield"
                                OptionValue={DobYear}
                                name="DobYear"
                                id="DobYear"
                                onChange={dateValidate}
                                myRef={validation({
                                    required: "Please Select Year"
                                })}
                                validationMsg={validationMsg.DobYear && validationMsg.DobYear.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobYear_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <span id="dob_final_err" className="error_msg mt-0" style={{display: finalError == '' ? 'none': 'block'}}>{finalError}</span>
                </div>
            </fieldset>
        </div>
    )
}

export { DateOfBirth };
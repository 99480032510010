const questinnare1 = {
  questionId: 5,
  question: "Have you previously looked into PPI",
  answers: [
    {
      label: "Yes",
      value: "10",
    },
    {
      label: "No",
      value: "11",
    },
    {
      label: "Don't Know",
      value: "44",
    },
  ],
};
const questinnare2 = {
  questionId: 6,
  question: "Did you receive a refund?",
  answers: [
    {
      label: "Yes",
      value: "12",
    },
    {
      label: "No",
      value: "13",
    },
    {
      label: "Don't Know",
      value: "45",
    },
  ],
};
const questinnare3 = {
  questionId: 7,
  question: "Was the refund in question received prior to 2017?",
  answers: [
    {
      label: "Yes",
      value: "14",
    },
    {
      label: "No",
      value: "15",
    },
    {
      label: "Don't Know",
      value: "46",
    },
  ],
};
const questinnare4 = {
  questionId: 8,
  question: "Was your claim rejected?",
  answers: [
    {
      label: "Yes",
      value: "16",
    },
    {
      label: "No",
      value: "17",
    },
    {
      label: "Don't Know",
      value: "47",
    },
  ],
};
const questinnare_1_4 = {
  questionId: 21,
  question: "Have you previously litigated this claim?",
  answers: [
    {
      label: "Yes",
      value: "58",
    },
    {
      label: "No",
      value: "59",
    },
    {
      label: "Don't Know",
      value: "60",
    },
  ],
};
const questinnare5 = {
  questionId: 9,
  question:
    "Are you aware if the PPI policy is still active on your credit facility?",
  answers: [
    {
      label: "Yes",
      value: "18",
    },
    {
      label: "No",
      value: "19",
    },
    {
      label: "Don't Know",
      value: "48",
    },
  ],
};
const questinnare6 = {
  questionId: 10,
  question:
    "Have you ever been made unemployed and if so, did you maintain payments to the lender?",
  answers: [
    {
      label: "Yes",
      value: "20",
    },
    {
      label: "No",
      value: "21",
    },
    {
      label: "Don't Know",
      value: "49",
    },
  ],
};

const questinnare7Sub1 = {
  question:
    "Q7 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  questionId: "sub1",
  answers: [
    {
      label: "Option-1",
      value: "Option-1",
    },
    {
      label: "Option-2",
      value: "Option-2",
    },
    {
      label: "Option-3",
      value: "Option-3",
    },
    {
      label: "Option-4",
      value: "Option-4",
    },
    {
      label: "Option-5",
      value: "Option-5",
    },
  ],
};
const questinnare7Sub2 = {
  question:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  questionId: "sub2",
  answers: [
    {
      label: "Yes",
      value: "yes",
    },
    {
      label: "No",
      value: "no",
    },
  ],
};

const questinnare7 = {
  questionId: 11,
  question:
    "Have you ever claimed on your policy for accident, sickness or unemployment?",
  answers: [
    {
      label: "Yes",
      value: "22",
    },
    {
      label: "No",
      value: "23",
    },
    {
      label: "Don't Know",
      value: "50",
    },
  ],
};
const questinnare8 = {
  questionId: 12,
  question:
    "Do you have any reason to believe the lender may have acted irresponsibly?",
  answers: [
    {
      label: "Yes",
      value: "24",
    },
    {
      label: "No",
      value: "25",
    },
    {
      label: "Don't Know",
      value: "51",
    },
  ],
};

const Salutation = [
  {
    label: "Select Title",
    value: "",
  },
  {
    label: "Mr.",
    value: "Mr",
  },
  {
    label: "Mrs.",
    value: "Mrs",
  },
  {
    label: "Ms.",
    value: "Ms",
  },
  {
    label: "Miss.",
    value: "Miss",
  },
  {
    label: "Dr.",
    value: "Dr",
  },
  {
    label: "Rev.",
    value: "Rev",
  },
  {
    label: "Dame.",
    value: "Dame",
  },
  {
    label: "Lady.",
    value: "Lady",
  },
  {
    label: "Sir.",
    value: "Sir",
  },
  {
    label: "Lord.",
    value: "Lord",
  },
];
const questinnare9 = {
  questionId: 13,
  question:
    "At the point of obtaining the credit, did you understand all the key risks and negative consequences posed within the credit agreement?",
  answers: [
    {
      label: "Yes",
      value: "26",
    },
    {
      label: "No",
      value: "27",
    },
    {
      label: "Don't Know",
      value: "52",
    },
  ],
};
const questinnare_5_1 = {
  questionId: 22,
  question:
    "Were you aware the bank received a commission for selling you the PPI policy?",
  answers: [
    {
      label: "Yes",
      value: "61",
    },
    {
      label: "No",
      value: "62",
    },
    {
      label: "Don't Know",
      value: "63",
    },
  ],
};
const questinnare_5_2 = {
  questionId: 23,
  question: "If you were aware, would you still have taken out the policy?",
  answers: [
    {
      label: "Yes",
      value: "64",
    },
    {
      label: "No",
      value: "65",
    },
    {
      label: "Don't Know",
      value: "66",
    },
  ],
};

const questinnare10 = {
  questionId: 14,
  question:
    "Have you ever struggled to keep up with repayments on your credit agreement?",
  answers: [
    {
      label: "Yes",
      value: "28",
    },
    {
      label: "No",
      value: "29",
    },
    {
      label: "Don't Know",
      value: "53",
    },
  ],
};

const questinnare11 = {
  questionId: 11,
  question:
    "Did you expect the advice you were given to be the best for your circumstances?",
  answers: [
    {
      label: "Yes",
      value: "46",
    },
    {
      label: "No",
      value: "47",
    },
    {
      label: "Don't Know",
      value: "54",
    },
  ],
};
const questinnare12 = {
  questionId: 12,
  question:
    "Does the bank contacting you over the phone cause you anxiety and stress?",
  answers: [
    {
      label: "Yes",
      value: "48",
    },
    {
      label: "No",
      value: "49",
    },
    {
      label: "Don't Know",
      value: "55",
    },
  ],
};

const questinnare16 = {
  questionId: 16,
  question: "Have you had a car finance with PCP or HP?",
  answers: [
    {
      label: "Yes",
      value: "32",
    },
    {
      label: "No",
      value: "33",
    },
    {
      label: "Don't Know",
      value: "56",
    },
  ],
};

const questinnare17 = {
  questionId: 17,
  question: "Did you keep the vehicle for more than 2 years?",
  answers: [
    {
      label: "Yes",
      value: "34",
    },
    {
      label: "No",
      value: "35",
    },
    {
      label: "Don't Know",
      value: "57",
    },
  ],
};

const questinnare18 = {
  questionId: 18,
  question: "How many vehicles have you had on finance after 2014?",
  answers: [
    {
      label: "1",
      value: "36",
    },
    {
      label: "2",
      value: "37",
    },
    {
      label: "3",
      value: "38",
    },
    {
      label: "4 and more",
      value: "39",
    },
  ],
};

const questinnare19 = {
  questionId: 19,
  question: "How did you pay for your deposit?",
  answers: [
    {
      label: "Credit Card",
      value: "40",
    },
    {
      label: "Cash",
      value: "41",
    },
    {
      label: "Bank transfer",
      value: "42",
    },
  ],
};

const questinnare20 = {
  questionId: 20,
  question: "Car Reg",
  answers: [],
};

export {
  questinnare1,
  questinnare_1_4,
  questinnare2,
  questinnare3,
  questinnare4,
  questinnare5,
  questinnare6,
  questinnare7,
  questinnare_5_1,
  questinnare_5_2,
  questinnare8,
  Salutation,
  questinnare7Sub1,
  questinnare7Sub2,
  questinnare11,
  questinnare12,
  questinnare9,
  questinnare10,
  questinnare16,
  questinnare17,
  questinnare18,
  questinnare19,
  questinnare20,
};

export const Questionnaire = [
  questinnare1,
  questinnare2,
  questinnare3,
  questinnare4,
  questinnare_1_4,
  questinnare5,
  questinnare6,
  questinnare7,
  questinnare8,
  questinnare9,
  questinnare_5_1,
  questinnare_5_2,
  questinnare10,
  questinnare11,
  questinnare12,
  questinnare16,
  questinnare17,
  questinnare18,
  questinnare19,
  questinnare20,
];

export const GtmQuestions = {
  5: "Previously looked into PPI",
  6: "Receive a refund?",
  7: "Refund recieved prior",
  8: "Claim rejected?",
  21: "Previously litigated?",
  9: "Still active?",
  10: "Maintain payments to the lender?",
  11: "Claimed your policy?",
  12: "Lender acted irresponsibly?",
  13: "Credit agreement",
  22: "Bank received a commission?",
  23: "Still have taken if aware?",
  14: "Struggled to repayments",
  16: "Had a car finance with PCP or HP",
  17: "Kept vehicle for more than 2 years",
  18: "Number of vehicles on finance after 2014",
  19: "Payment method",
  20: "Car Reg",
};
